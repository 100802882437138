import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TabPanel from '../../components/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import styles from '../../styles/GroupTrainingMedia.module.css';
import { styled } from '@mui/material/styles';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useToggle from '../../hooks/useToggle';
import YouTube from 'react-youtube';
import { useAppDispatch, useAppSelector } from '../../store';
import { deleteMediaPost, getPosts } from '../../store/groupTrainingPost/api';
import {
  PostObject,
  ShareFormInfoResponse,
} from '../../store/groupTrainingPost/api/interface';
import { IHomeParams } from '../../enum';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  incrementCurrentPage,
  postDeleted,
} from '../../store/groupTrainingPost/groupTrainingPostSlice';
import { Stack } from '@mui/system';
import moment from 'moment';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import ConfirmationModel from '../../components/modal/ConfirmationModel';
import Page from '../../components/Page';
import { isMobile } from '../../utils/getMobileOperatingSystem';
import { getTopicList } from '../../store/groupTrainingMediaTopic/api';
import { TopicObject } from '../../store/groupTrainingMediaTopic/api/interface';
import { showSuccessMessage } from '../../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import { AppColors } from '../../utils/utils';
import AddTopicIcon from '../../assets/media/add_topic.svg';
import AddMediaIcon from '../../assets/media/add_media.svg';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import playIcon from '../../assets/play_circle.svg';
import AddGroupTrainingMediaTopicModal from '../../components/modal/AddGroupTrainingMediaTopicModal';
import ViewGroupTrainingMediaPostModal from '../../components/modal/ViewGroupTrainingMediaPostModal';
import AddGroupTrainingMediaModal from '../../components/modal/AddGroupTrainingMediaModal';
import EditGroupTrainingMediaModal from '../../components/modal/EditGroupTrainingMediaModal';
import { formatVideoDuration } from '../../utils/format';
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../utils/valid';

const GalleryTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  minHeight: '50px',
  '&.Mui-selected': {
    backgroundColor: AppColors.primaryColor,
    color: AppColors.whiteColor,
    borderRadius: '10px',
  },
}));

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const GroupTrainingMedia: React.FC = () => {
  const params = useParams<IHomeParams>();
  const [postTab, setPostTab] = useState(0);
  const matches = useMediaQuery('(max-width:745px)');
  const { toggle, onOpen, onClose } = useToggle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const userInfo = useAppSelector((state) => state?.auth?.user);
  // const isProUser = checkIsProUser(userInfo);
  const posts =
    useAppSelector((state) => state?.groupTrainingPost.data?.data) ?? [];
  const isLoading = useAppSelector(
    (state) => state?.groupTrainingPost?.isLoading
  );
  const hasMore = useAppSelector((state) => state?.groupTrainingPost?.hasMore);
  const currentPage = useAppSelector(
    (state) => state?.groupTrainingPost?.currentPage
  );

  const topicData =
    useAppSelector((state) => state?.groupTrainingMediaTopic.data?.data) ?? [];
  const topicIsLoading = useAppSelector(
    (state) => state?.groupTrainingMediaTopic?.isLoading
  );
  const topicHasMore = useAppSelector(
    (state) => state?.groupTrainingMediaTopic?.hasMore
  );
  const topicCurrentPage = useAppSelector(
    (state) => state?.groupTrainingMediaTopic?.currentPage
  );
  const [isPinedPostExpanded, setIsPinedPostExpanded] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<number | undefined>(
    undefined
  );
  const [activePost, setActivePost] = useState<PostObject>();
  const [selectedPost, setSelectedPostMenu] = useState<
    PostObject | undefined
  >();
  const [manuallyClicked, setManuallyClicked] = useState<boolean>(false);
  const [shareFormInfoResponse, setShareFormInfoResponse] =
    useState<ShareFormInfoResponse>();
  const observer = useRef<IntersectionObserver>();
  const [searchParams] = useSearchParams();

  const [editPost, setEditPost] = useState<PostObject | undefined>();
  const [deletePost, setDeletePost] = useState<PostObject | undefined>();

  const [isShareBuyNowLink, setShareBuyNowLink] = useState<boolean>(false);

  const [hoverItem, setHoverItem] = useState<number | undefined>();
  const [videoDuration, setVideoDuration] = useState<number | undefined>();
  const [isExpanded, setExpanded] = useState(false);
  const scrollRef = useRef<any>();

  const {
    toggle: toggleMediaForm,
    onOpen: onOpenMediaForm,
    onClose: onCloseMediaForm,
  } = useToggle();

  const {
    toggle: toggleEditMediaForm,
    onOpen: onOpenEditMediaForm,
    onClose: onCloseEditMediaForm,
  } = useToggle();

  const {
    toggle: togglenDeleteMedia,
    onOpen: onOpenDeleteMedia,
    onClose: onCloseDeleteMedia,
  } = useToggle();

  const {
    toggle: toggleAddTopic,
    onOpen: onOpenAddTopic,
    onClose: onCloseAddTopic,
  } = useToggle();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [totalSlide, setTotalSlide] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = React.useState<any>(0);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
    onMouseLeaveEvent();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenu = (post: PostObject) => {
    setEditPost(selectedPost ?? post);
    onOpenEditMediaForm();
    setAnchorEl(null);
    setSelectedPostMenu(undefined);
  };

  const handleDeleteMenu = (post: PostObject) => {
    setDeletePost(selectedPost ?? post);
    onOpenDeleteMedia();
    setAnchorEl(null);
    setSelectedPostMenu(undefined);
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        getPosts({
          group_id: userInfo?.affiliate_group?.id!,
          page: currentPage,
          topic_id: selectedTopic,
        })
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (userId) {
      dispatch(
        getTopicList({
          group_id: userInfo?.affiliate_group?.id!,
          page: topicCurrentPage,
        })
      );
    }
  }, [topicCurrentPage]);

  useEffect(() => {
    if (userId) {
      if (currentPage != 0) {
        dispatch(incrementCurrentPage(0));
      } else {
        dispatch(
          getPosts({
            group_id: userInfo?.affiliate_group?.id!,
            page: currentPage,
            topic_id: selectedTopic,
          })
        );
      }
    }
  }, [selectedTopic]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPostTab(newValue);
  };

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(incrementCurrentPage(currentPage + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleActivePost = (post: PostObject) => {
    const sc = searchParams.get('sc');
    onOpen();
    setManuallyClicked(true);
    setActivePost(post);
  };

  const onDeleteMediaConfirm = async () => {
    onCloseDeleteMedia();
    if (deletePost != undefined) {
      try {
        var result = await dispatch(
          deleteMediaPost({ user_id: userId, postId: deletePost.id })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(postDeleted(deletePost));
          showSuccessMessage('Media post deleted successfully.');
          setDeletePost(undefined);
        }
      } catch (error: any) {}
    }
  };

  const onMouseEnterEvent = (post: PostObject) => {
    setHoverItem(post.id);
    getVideoDuration(post.contents[0].link);
  };

  const onMouseLeaveEvent = () => {
    setHoverItem(undefined);
    setVideoDuration(undefined);
    setExpanded(false);
  };

  const getVideoDuration = (link: string) => {
    const media = new Audio(link);
    media.onloadeddata = (e) => {
      setVideoDuration(media.duration);
    };
  };

  const executeScroll = (id: string, scrollOffset: any) => {
    const section = document.querySelector(`#${id}`) as HTMLElement;
    if (section) {
      section.scrollLeft += scrollOffset;
    }
  };

  const handleOnCommandCenterClicked = () => {
    navigate('/dashboard/leader-corner');
  };

  const getPostListFromTopicId = (
    topicId: number | undefined | null
  ): PostObject[] => {
    return (topicId ?? 0) > 0
      ? posts.filter((e) => e.topic_id == topicId)
      : posts.filter((e) => e.topic_id == null || e.topic_id == undefined);
  };

  return (
    <>
      <Page title='Team Training | KnoCard' showAppBar={true}>
        <Stack>
          <Box
            component={'img'}
            // className='cover-img'
            src={
              isNotEmpty(userInfo?.affiliate_group?.background_link)
                ? userInfo?.affiliate_group?.background_link
                : '/static/images/group_bg/knocard.jpg'
            }
            sx={{
              borderRadius: '12px',
              objectFit: 'cover',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              width: '100%',
              mt: 2,
            }}
          />
          {
            <Box
              p={1}
              display='flex'
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{
                backgroundColor: '#FDFCFD',
              }}
            >
              {userInfo?.username == userInfo?.affiliate_group?.owner_name && (
                <Button
                  variant='outlined'
                  sx={{
                    fontWeight: '500',
                    fontSize: '1.3rem',
                    textTransform: 'capitalize',
                    margin: '20px',
                    color: AppColors.whiteColor,
                    background: AppColors.primaryColor,
                    borderRadius: '12px',
                    minWidth: { xs: '50%', md: '20%' },
                    maxHeight: '70px',
                    ':hover': {
                      color: AppColors.primaryColor,
                    },
                  }}
                  onClick={handleOnCommandCenterClicked}
                >
                  Go To Command Center
                </Button>
              )}

              <Grid
                container
                spacing={isMobile() ? 1 : 3}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {[
                  { id: -1, title: '', link: '' } as TopicObject,
                  ...topicData,
                ].map((item, index) =>
                  index === 0 ? (
                    <>
                      {userInfo?.username ==
                        userInfo?.affiliate_group?.owner_name && (
                        <Grid item key={index} display={'flex'}>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            textAlign={'center'}
                            p={1}
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => onOpenAddTopic()}
                          >
                            <Box
                              component={'img'}
                              src={AddTopicIcon}
                              sx={{
                                height: '50px',
                                width: '50px',
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: '0.8rem',
                                color: AppColors.grayThreeColor,
                                mt: 1,
                              }}
                            >
                              Add Topic
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        item
                        // xs
                        key={index}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => setSelectedTopic(undefined)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Stack
                          alignItems={'center'}
                          sx={{
                            background:
                              selectedTopic === undefined
                                ? AppColors.grayZeroColor
                                : 'unset',
                            p: 1,
                            borderRadius: '10px',
                          }}
                        >
                          <img
                            // component='img'
                            height='50px'
                            width='50px'
                            src={'/static/images/knocard.png'}
                            alt={'All'}
                            style={{
                              borderRadius: 100,
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              color: AppColors.grayThreeColor,
                              mt: 1,
                            }}
                          >
                            All
                          </Typography>
                        </Stack>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      // xs
                      key={index}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() =>
                        setSelectedTopic(
                          selectedTopic !== topicData[index - 1].id
                            ? topicData[index - 1].id
                            : undefined
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Stack
                        alignItems={'center'}
                        sx={{
                          background:
                            selectedTopic === topicData[index - 1].id
                              ? AppColors.grayZeroColor
                              : 'unset',
                          p: 1,
                          borderRadius: '10px',
                        }}
                      >
                        <img
                          // component='img'
                          height='50px'
                          width='50px'
                          src={item.link}
                          alt={item.title}
                          style={{
                            borderRadius: 100,
                          }}
                        />

                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            color: AppColors.grayThreeColor,
                            mt: 1,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Stack>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          }
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='100%'

            //sx={{ backgroundColor: "#f1f2f6" }}
          >
            <Tabs
              value={postTab}
              onChange={handleChange}
              variant='fullWidth'
              sx={{
                width: '100%',
                borderRadius: '10px',
                backgroundColor: AppColors.whiteColor,
                pl: { xs: 1, md: 0 },
                pr: { xs: 1, md: 0 },
              }}
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
            >
              <GalleryTab
                icon={
                  <GridViewOutlinedIcon
                    sx={{
                      color:
                        postTab == 0
                          ? AppColors.whiteColor
                          : AppColors.blackColor,
                    }}
                  />
                }
              />
              <GalleryTab
                icon={
                  <FormatListBulletedIcon
                    sx={{
                      color:
                        postTab == 1
                          ? AppColors.whiteColor
                          : AppColors.blackColor,
                    }}
                  />
                }
              />
            </Tabs>
          </Box>

          <TabPanel value={postTab} index={0} p='18px 0 0 0'>
            {[{ id: -1, title: 'All', link: '' }, ...topicData].map(
              (topicItem, topicInx) => {
                const postItems = getPostListFromTopicId(topicItem.id);

                return !selectedTopic || topicItem.id === selectedTopic ? (
                  <Box
                    key={`topic-${topicInx}`}
                    sx={{
                      mb: 8,
                    }}
                  >
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      sx={{
                        background:
                          selectedTopic === topicItem.id
                            ? AppColors.grayZeroColor
                            : 'unset',
                        p: 1,
                        borderRadius: '10px',
                      }}
                    >
                      <img
                        // component='img'
                        height='40px'
                        width='40px'
                        src={
                          topicItem.link.length > 0
                            ? topicItem.link
                            : '/static/images/knocard.png'
                        }
                        alt={topicItem.title}
                        style={{
                          borderRadius: 100,
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: '1rem',
                          color: 'black',
                          ml: 1,
                        }}
                      >
                        {topicItem.title}
                      </Typography>
                    </Stack>

                    <Grid container direction={'row'} alignItems={'center'}>
                      <Grid item md={11}>
                        <Stack
                          id={`topic-items-${topicInx}`}
                          direction={'row'}
                          display={'flex'}
                          sx={{ overflowY: 'hidden' }}
                          // component={'div'}
                          ref={scrollRef}
                        >
                          <Box className={styles.mediaContainerGrid}>
                            {userInfo?.username ==
                              userInfo?.affiliate_group?.owner_name && (
                              <Box
                                className={styles.mediaElementAdd}
                                sx={{ backgroundColor: AppColors.whiteColor }}
                                onClick={() =>
                                  userInfo?.username ==
                                    userInfo?.affiliate_group?.owner_name &&
                                  onOpenMediaForm()
                                }
                              >
                                <Box
                                  component={'img'}
                                  src={AddMediaIcon}
                                  alt='addMedia'
                                  sx={{ width: '20%' }}
                                />
                                <Typography
                                  sx={{
                                    color: AppColors.grayThreeColor,
                                    fontSize: '1rem',
                                    ml: 1,
                                  }}
                                >
                                  Add Training Media
                                </Typography>
                              </Box>
                            )}

                            {postItems?.map((post, idx) => {
                              return (
                                <Box
                                  ref={
                                    idx +
                                      (userInfo?.username ==
                                      userInfo?.affiliate_group?.owner_name
                                        ? 1
                                        : 0) ===
                                    postItems?.length
                                      ? lastElementRef
                                      : undefined
                                  }
                                  key={`post-${idx}`}
                                  className={styles.mediaContainer}
                                  onMouseEnter={() => onMouseEnterEvent(post)}
                                  onMouseLeave={onMouseLeaveEvent}
                                >
                                  <Box
                                    // className={styles.mediaElement}
                                    height={'300px'}
                                    width={'300px'}
                                    style={{
                                      backgroundImage: `url(${
                                        post?.thumb_link
                                          ? post?.thumb_link
                                          : post?.contents?.[0]?.type === 'pdf'
                                          ? '/static/images/pdf.png'
                                          : post?.contents?.[0]?.type ===
                                            'audio'
                                          ? '/static/images/mp3.png'
                                          : post?.contents?.[0]?.type ===
                                            'video'
                                          ? post?.contents?.[0]?.thumbnail ?? ''
                                          : post?.contents?.[0]?.link ?? ''
                                      })`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      position: 'relative',
                                      ...(!post?.thumb_link &&
                                      post?.contents?.[0]?.type === 'pdf'
                                        ? {
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                          }
                                        : {}),
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                      }}
                                      onClick={() => handleActivePost(post)}
                                    >
                                      &nbsp;
                                    </Box>

                                    {post?.contents?.[0]?.type === 'video' && (
                                      <Box
                                        component={'img'}
                                        src={playIcon}
                                        sx={{
                                          height: '30%',
                                          width: '30%',
                                          color: '#dfe2e4',
                                          position: 'absolute',
                                          top: '35%',
                                          left: '35%',
                                          fontSize: '4rem',
                                        }}
                                        onClick={() => handleActivePost(post)}
                                      />
                                    )}

                                    {userInfo?.username ==
                                      userInfo?.affiliate_group?.owner_name && (
                                      <Box
                                        sx={{
                                          backgroundColor: '#000000ad',
                                          borderRadius: '50%',
                                          height: '40px',
                                          width: '40px',
                                          position: 'absolute',
                                          top: '10px',
                                          right: '10px',
                                        }}
                                      >
                                        <IconButton
                                          aria-label='more'
                                          id='long-button'
                                          aria-controls={
                                            menuOpen && activeMenuIndex == idx
                                              ? 'long-menu'
                                              : undefined
                                          }
                                          aria-expanded={
                                            menuOpen && activeMenuIndex == idx
                                              ? 'true'
                                              : undefined
                                          }
                                          aria-haspopup='true'
                                          onClick={(event) => {
                                            setSelectedPostMenu(post);
                                            handleMenuClick(event, idx);
                                          }}
                                        >
                                          <MoreVertIcon
                                            sx={{ color: 'white' }}
                                          />
                                        </IconButton>
                                        <Menu
                                          id='long-menu'
                                          MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                          }}
                                          anchorEl={anchorEl}
                                          open={
                                            menuOpen && activeMenuIndex == idx
                                          }
                                          onClose={handleMenuClose}
                                          PaperProps={{
                                            style: {
                                              maxHeight: 48 * 4.5,
                                              width: '20ch',
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            key={`edit-media-${idx}`}
                                            onClick={() => handleEditMenu(post)}
                                          >
                                            Edit
                                          </MenuItem>
                                          <MenuItem
                                            key={`delete-media-${idx}`}
                                            onClick={() =>
                                              handleDeleteMenu(post)
                                            }
                                          >
                                            Delete
                                          </MenuItem>
                                        </Menu>
                                      </Box>
                                    )}
                                  </Box>
                                  {hoverItem == post.id &&
                                    (post.contents[0].type == 'video' ||
                                      post.contents[0].type == 'audio') && (
                                      <Box
                                        style={
                                          {
                                            // padding: '5px',
                                            // marginLeft: '-5px',
                                            // marginRight: '-5px',
                                            // position: 'fixed',
                                            // backgroundColor: '#3B3838',
                                            // borderRadius: '0 0 10px 10px',
                                            // width: '14.1%',
                                          }
                                        }
                                      >
                                        <Grid
                                          container
                                          alignItems={'center'}
                                          mt={1}
                                        >
                                          <Grid item xs>
                                            <Typography
                                              component={'p'}
                                              variant='body1'
                                              color={'white'}
                                            >
                                              {post.description}
                                            </Typography>
                                          </Grid>
                                          {(
                                            post.contents[0].description ?? ''
                                          ).trim().length > 0 && (
                                            <Grid item>
                                              <IconButton
                                                sx={{ p: 0 }}
                                                aria-label='showDescription'
                                                onClick={(event) =>
                                                  setExpanded(!isExpanded)
                                                }
                                              >
                                                {isExpanded ? (
                                                  <ExpandCircleDownOutlinedIcon
                                                    sx={{
                                                      color: 'white',
                                                      transform:
                                                        'rotate(180deg)',
                                                    }}
                                                  />
                                                ) : (
                                                  <ExpandCircleDownOutlinedIcon
                                                    sx={{
                                                      color: 'white',
                                                    }}
                                                  />
                                                )}
                                              </IconButton>
                                            </Grid>
                                          )}
                                        </Grid>
                                        {isExpanded && (
                                          <Typography
                                            component={'p'}
                                            variant='body2'
                                            color={'lightgray'}
                                            mb={0.5}
                                          >
                                            {post.contents[0].description}
                                          </Typography>
                                        )}
                                        <Grid
                                          container
                                          alignItems={'center'}
                                          mt={0.1}
                                        >
                                          <PlayCircleFilledOutlinedIcon
                                            sx={{ color: 'white' }}
                                          />
                                          {videoDuration && (
                                            <Typography
                                              ml={1}
                                              component={'p'}
                                              variant='body2'
                                              color={'white'}
                                              fontWeight={'bold'}
                                            >
                                              {formatVideoDuration(
                                                videoDuration ?? 0
                                              )}
                                            </Typography>
                                          )}
                                        </Grid>
                                        {/* <Grid
                                      container
                                      spacing={1}
                                      alignItems={'center'}
                                      mt={0.1}
                                    >
                                      {['CRM', 'Pipeline', 'Reporting'].map(
                                        (data) => {
                                          return (
                                            <Grid item>
                                              <Typography
                                                component={'p'}
                                                variant='body2'
                                                color={'primary'}
                                                fontWeight={'bold'}
                                              >
                                                {data}
                                              </Typography>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </Grid> */}
                                      </Box>
                                    )}
                                </Box>
                              );
                            })}
                            {/* {posts?.length < 4 &&
                        Array(3)
                          .fill(0)
                          .map((item, index) => {
                            return <Box key={`emptyitem-${index}`}></Box>;
                          })} */}
                          </Box>
                        </Stack>
                      </Grid>
                      {!isMobile() &&
                        (userInfo?.username ==
                        userInfo?.affiliate_group?.owner_name
                          ? 1
                          : 0) +
                          postItems.length >
                          5 && (
                          <Grid item md={1}>
                            <Stack
                              alignItems={'center'}
                              onClick={() =>
                                executeScroll(`topic-items-${topicInx}`, -400)
                              }
                            >
                              <ExpandCircleDownIcon
                                sx={{
                                  color: AppColors.primaryColor,
                                  transform: 'rotate(90deg)',
                                  fontSize: '3rem',
                                }}
                              />
                              {/* <Typography
                            sx={{
                              fontSize: '1rem',
                              color: AppColors.grayThreeColor,
                              mt: 1,
                            }}
                          >
                            Less
                          </Typography> */}
                            </Stack>

                            <Stack
                              alignItems={'center'}
                              mt={2}
                              onClick={() =>
                                executeScroll(`topic-items-${topicInx}`, 400)
                              }
                            >
                              <ExpandCircleDownIcon
                                sx={{
                                  color: AppColors.primaryColor,
                                  transform: 'rotate(270deg)',
                                  fontSize: '3rem',
                                }}
                              />
                              {/* <Typography
                            sx={{
                              fontSize: '1rem',
                              color: AppColors.grayThreeColor,
                              mt: 1,
                            }}
                          >
                            More
                          </Typography> */}
                            </Stack>
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                ) : (
                  <div></div>
                );
              }
            )}
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value={postTab}
            index={1}
            p='25px 0 0 0'
            // backgroundColor="#f1f2f6"
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={1}
            >
              {userInfo?.username == userInfo?.affiliate_group?.owner_name && (
                <Box
                  className={styles.mediaElementAdd}
                  sx={{
                    backgroundColor: AppColors.whiteColor,
                    width: '100%',
                    pt: 10,
                    pb: 10,
                  }}
                  onClick={() =>
                    userInfo?.username ==
                      userInfo?.affiliate_group?.owner_name && onOpenMediaForm()
                  }
                >
                  <Box
                    component={'img'}
                    src={AddMediaIcon}
                    alt='addMedia'
                    sx={{ width: '80px' }}
                  />
                  <Typography
                    sx={{ color: AppColors.grayThreeColor, fontSize: '1rem' }}
                  >
                    Add Training Media
                  </Typography>
                </Box>
              )}

              {posts?.map((post, idx) => {
                return (
                  <Card
                    ref={
                      idx +
                        (userInfo?.username ==
                        userInfo?.affiliate_group?.owner_name
                          ? 1
                          : 0) ===
                      posts?.length
                        ? lastElementRef
                        : undefined
                    }
                    key={idx}
                    sx={{
                      width: '100%',
                      boxShadow: 'unset !important',
                      p: 0,
                    }}
                    className='we-are-live-post-card'
                  >
                    <Stack direction='column' gap={1}>
                      <Box
                        display='flex'
                        alignItems='center'
                        height='60px'
                        sx={{ pl: 2, pr: 2, pt: 1 }}
                      >
                        <Box width='50px'>
                          <Avatar
                            alt='pic'
                            src={
                              userInfo?.profile_picture ??
                              '/static/images/placeholder_profile.png'
                            }
                          />
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          justifyContent='center'
                          flex={1}
                        >
                          <Typography
                            variant='h6'
                            component='div'
                            color='black'
                            className='post-main-title'
                            sx={{ color: AppColors.blackColor }}
                          >
                            {userInfo?.name}
                          </Typography>
                          <Typography
                            variant='h6'
                            component='div'
                            color='primary'
                            fontSize='10px'
                            className='post-main-sub-title'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {userInfo?.occupation}
                          </Typography>
                        </Box>
                        {userInfo?.username ==
                          userInfo?.affiliate_group?.owner_name && (
                          <Box width='50px'>
                            <IconButton
                              aria-label='more'
                              id='long-button'
                              aria-controls={
                                menuOpen && activeMenuIndex == idx
                                  ? 'long-menu'
                                  : undefined
                              }
                              aria-expanded={
                                menuOpen && activeMenuIndex == idx
                                  ? 'true'
                                  : undefined
                              }
                              aria-haspopup='true'
                              onClick={(event) => handleMenuClick(event, idx)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id='long-menu'
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={menuOpen && activeMenuIndex == idx}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {/* <MenuItem
                                key={`pin-${idx}`}
                                onClick={() => handlePinMenu(post)}
                              >
                                {post.is_pined == 1 ? 'Unpin' : 'Pin'}
                              </MenuItem> */}
                              <MenuItem
                                key={`edit-media-${idx}`}
                                onClick={() => handleEditMenu(post)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                key={`delete-media-${idx}`}
                                onClick={() => handleDeleteMenu(post)}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </Box>
                      <Splide
                        key={`media-splide-${post?.id}`}
                        aria-label='Images'
                        options={{
                          perPage: 1,
                          fixedHeight: 390,
                        }}
                        onMove={(event) => {
                          setActiveSlide(event.index);
                        }}
                        onReady={(event) => {
                          setTotalSlide(event.length);
                        }}
                      >
                        {post?.contents?.map((content, sIdx) => {
                          return content?.type === 'image' ? (
                            <SplideSlide key={content.id}>
                              <Box
                                component={'img'}
                                src={content?.link}
                                alt={content?.filename}
                                style={{ maxHeight: '50vh' }}
                                height='100%'
                                width='100%'
                                className='object-fit-contain'
                              />
                            </SplideSlide>
                          ) : content?.type === 'pdf' ? (
                            <SplideSlide key={content.id}>
                              {activeSlide == sIdx && (
                                <iframe
                                  key={`iframe-${content.id}`}
                                  src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                                  itemType='application/pdf'
                                  width='100%'
                                  height='100%'
                                  style={{ overflowY: 'scroll' }}
                                ></iframe>
                              )}
                            </SplideSlide>
                          ) : content?.type === 'audio' ? (
                            <SplideSlide key={content.id}>
                              {activeSlide == sIdx && (
                                <audio
                                  controls
                                  playsInline
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '50vh',
                                  }}
                                  // className='object-fit-contain'
                                >
                                  <source src={content?.link} />
                                  Your browser does not support the audio tag.
                                </audio>
                              )}
                            </SplideSlide>
                          ) : (
                            <SplideSlide key={content.id}>
                              {content?.platform === 'youtube' ? (
                                <YouTube
                                  videoId={content?.link}
                                  opts={opts}
                                  className='object-fit-contain'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '50vh',
                                  }}
                                />
                              ) : content?.platform === 'vimeo' ? (
                                <Vimeo
                                  video={content?.link}
                                  className='object-fit-contain vimeo-video-contain'
                                  height={'100%'}
                                  width={'100%'}
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '50vh',
                                  }}
                                  // onPlay={() => handleOnplayVideo(content.id)}
                                />
                              ) : (
                                <video
                                  controls
                                  width='100%'
                                  height='100%'
                                  style={{ maxHeight: '50vh' }}
                                  className='object-fit-contain'
                                  poster={content?.thumbnail ?? ''}
                                >
                                  <source
                                    src={content?.link}
                                    type='video/mp4'
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </SplideSlide>
                          );
                        })}
                      </Splide>
                      {post?.contents && post?.contents?.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            pl: 2,
                            pr: 2,
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: AppColors.lightYellowColor,
                              color: AppColors.blackColor,
                              textTransform: 'none',
                              fontSize: '0.8rem',
                              fontWeight: 400,
                              zIndex: 1000,
                              lineHeight: 'normal',
                              mt: 0.3,
                            }}
                          >
                            {`${activeSlide + 1}`} of {totalSlide ?? 0}
                          </Button>
                        </Box>
                      )}
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ pl: 2, pr: 2 }}
                      >
                        {/* <Stack
                          direction='row'
                          color='grey'
                          alignItems='center'
                          gap={1}
                        >
                          <RemoveRedEyeOutlinedIcon color='inherit' />
                          <Typography
                            variant='body2'
                            component='div'
                            color='inherit'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {post?.views_count} Views
                          </Typography>
                        </Stack> */}
                        <Typography
                          variant='body2'
                          component='div'
                          sx={{ color: AppColors.grayTwoColor }}
                        >
                          {moment(post?.created_at).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        sx={{ pl: 2, pr: 2, pb: 1 }}
                      >
                        <Typography
                          variant='body1'
                          component='div'
                          sx={{ color: AppColors.grayThreeColor }}
                        >
                          {post?.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                );
              })}
              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </TabPanel>
          {/* <BlockOverlay isBlock={!isProUser} /> */}
        </Stack>
      </Page>
      <ViewGroupTrainingMediaPostModal
        toggle={toggle}
        onClose={onClose}
        activePost={activePost}
        shareFormInfoResponse={shareFormInfoResponse}
        setShareFormInfoResponse={setShareFormInfoResponse}
        manuallyClicked={manuallyClicked}
        isShareBuyNowLink={isShareBuyNowLink}
      />
      {editPost &&
        userInfo?.username == userInfo?.affiliate_group?.owner_name &&
        toggleEditMediaForm && (
          <EditGroupTrainingMediaModal
            toggle={toggleEditMediaForm}
            onClose={onCloseEditMediaForm}
            postObject={editPost}
          />
        )}
      {userInfo?.username == userInfo?.affiliate_group?.owner_name &&
        toggleMediaForm && (
          <AddGroupTrainingMediaModal
            onClose={onCloseMediaForm}
            toggle={toggleMediaForm}
          />
        )}
      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete Media'
        message='Are you sure! Do you want to delete this media?'
        onConfirm={onDeleteMediaConfirm}
        onClose={onCloseDeleteMedia}
        toggle={togglenDeleteMedia}
      />
      <AddGroupTrainingMediaTopicModal
        groupId={userInfo?.affiliate_group?.id!}
        onClose={onCloseAddTopic}
        toggle={toggleAddTopic}
      />
    </>
  );
};

export default GroupTrainingMedia;
