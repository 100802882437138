import { createSlice } from '@reduxjs/toolkit';
import {
  changeAffiliateGroupBanner,
  createEditAffiliateGroup,
  findAffiliateGroup,
  findAffiliateGroupPricing,
  getAffiliateGroupLandingPageVideos,
  getAffiliateGroupMedia,
  getAffiliateGroupSocialPosts,
  toggleGroupShareStatus,
  updateActiveStatus,
  updateSocialPostActiveStatus,
} from './api';
import { initialState } from './api/interface';

const affiliateGroupSlice = createSlice({
  name: 'affiliateGroup',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    groupUpdated: (state, action) => {
      state.data.forEach((item: any, index: number) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      });
    },
    newMediaCreated: (state, action) => {
      state.affiliateGroupMedia.unshift(action.payload);
    },
    mediaPostUpdated: (state, action) => {
      state.affiliateGroupMedia.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.affiliateGroupMedia[index] = action.payload;
        }
      });
    },
    postDeleted: (state, action) => {
      let array = [...state.affiliateGroupMedia];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1) {
        array.splice(position, 1);
        state.affiliateGroupMedia = array;
      }
    },
    newSocialPostCreated: (state, action) => {
      state.affiliateGroupSocialPosts.unshift(action.payload);
    },
    socialPostUpdated: (state, action) => {
      state.affiliateGroupSocialPosts.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.affiliateGroupSocialPosts[index] = action.payload;
        }
      });
    },
    socialPostDeleted: (state, action) => {
      let array = [...state.affiliateGroupSocialPosts];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1) {
        array.splice(position, 1);
        state.affiliateGroupSocialPosts = array;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findAffiliateGroup.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(findAffiliateGroup.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.data = [...action.payload.data.data];
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(findAffiliateGroup.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(findAffiliateGroupPricing.pending, (state, _) => {
      state.isPricingLoading = true;
    });
    builder.addCase(findAffiliateGroupPricing.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupPricing = [...action.payload.data.data];
      }
      state.isPricingLoading = false;
    });
    builder.addCase(findAffiliateGroupPricing.rejected, (state, _) => {
      state.isPricingLoading = false;
    });

    builder.addCase(createEditAffiliateGroup.pending, (state) => {});
    builder.addCase(createEditAffiliateGroup.fulfilled, (state, _) => {});
    builder.addCase(createEditAffiliateGroup.rejected, (state, action) => {});

    builder.addCase(changeAffiliateGroupBanner.pending, (state) => {});
    builder.addCase(changeAffiliateGroupBanner.fulfilled, (state, _) => {});
    builder.addCase(changeAffiliateGroupBanner.rejected, (state, action) => {});

    builder.addCase(getAffiliateGroupMedia.pending, (state) => {});
    builder.addCase(getAffiliateGroupMedia.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupMedia = [...action.payload.data.data];
      }
    });
    builder.addCase(getAffiliateGroupMedia.rejected, (state, action) => {});

    builder.addCase(updateActiveStatus.pending, (state) => {});
    builder.addCase(updateActiveStatus.fulfilled, (state, action) => {});
    builder.addCase(updateActiveStatus.rejected, (state, action) => {});

    builder.addCase(toggleGroupShareStatus.pending, (state) => {});
    builder.addCase(toggleGroupShareStatus.fulfilled, (state, action) => {});
    builder.addCase(toggleGroupShareStatus.rejected, (state, action) => {});

    // Get group social posts
    builder.addCase(getAffiliateGroupSocialPosts.pending, (state) => {
      state.isSocialPostLoading = true;
    });
    builder.addCase(getAffiliateGroupSocialPosts.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupSocialPosts = [
          ...(action.payload.data.data.current_page == 1
            ? []
            : state.affiliateGroupSocialPosts ?? []),
          ...action.payload.data.data.data,
        ];
        state.isSocialPostLoadMore = action.payload.data.data.data.length >= 10;
        state.socialPostPage = action.payload.data.data.current_page + 1;
      }
      state.isSocialPostLoading = false;
    });
    builder.addCase(getAffiliateGroupSocialPosts.rejected, (state, action) => {
      state.isSocialPostLoading = false;
    });

    builder.addCase(updateSocialPostActiveStatus.pending, (state) => {});
    builder.addCase(
      updateSocialPostActiveStatus.fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      updateSocialPostActiveStatus.rejected,
      (state, action) => {}
    );

    builder.addCase(getAffiliateGroupLandingPageVideos.pending, (state) => {});
    builder.addCase(
      getAffiliateGroupLandingPageVideos.fulfilled,
      (state, action) => {
        if (action.payload.data.data != null) {
          state.affiliateLandingPageVideos = [...action.payload.data.data];
        }
      }
    );
    builder.addCase(
      getAffiliateGroupLandingPageVideos.rejected,
      (state, action) => {}
    );
  },
});

export const {
  setStatus,
  reset,
  groupUpdated,
  newMediaCreated,
  mediaPostUpdated,
  postDeleted,
  newSocialPostCreated,
  socialPostUpdated,
  socialPostDeleted,
} = affiliateGroupSlice.actions;
export default affiliateGroupSlice.reducer;
