import moment from 'moment';

export const dateFormate = (date: string, format?: string) => {
  if (format) return moment(date).format(format);
  else return moment(date).format('ll');
};


export const formatVideoDuration = (durationInSeconds: number): string => {
  // Ensure the duration is a non-negative number
  if (durationInSeconds < 0) {
    throw new Error('Duration must be a positive number.');
  }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  // Format the output based on the time breakdown
  if (hours > 0) {
    // Format like "1:45 hr" (H:MM hr)
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} hr`;
  } else if (minutes > 0) {
    // Format like "45 min" (M min)
    return `${minutes} min`;
  } else {
    // Format like "19 sec" (S sec)
    return `${seconds} sec`;
  }
};

export const formatTime = (time: string): string => {
  const [hours, minutes, seconds] = time.split(':').map(Number);

  // Format the time based on hours, minutes, and seconds
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}hr `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}min `;
  }
  if (seconds >= 0) {
    formattedTime += `${seconds}sec`;
  }

  return formattedTime.trim();
};

export const convertToTimeString = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // Format hours, minutes, and seconds with two digits
  const formatted = [];
  if (hours > 0) {
    formatted.push(hours.toString().padStart(2, '0'));
  }
  formatted.push(minutes.toString().padStart(hours > 0 ? 2 : 1, '0'));
  formatted.push(remainingSeconds.toString().padStart(2, '0'));

  return formatted.join(':');
};