import { STATUS_TYPE } from '../../../enum';
import { GroupMediaItem } from '../../corporateReporting/api/interface';
import { AffiliateLandingPageWatchVideoItem } from '../../reports/api/interface';

export interface AffiliateGroup {
  id: number;
  name: string;
  owner_name: string;
  status: number;
  price: number;
  background_link?: string;
  created_at: Date;
  updated_at: Date;
  group_pricing?: AffiliateGroupPricing;
  brand_logo_link?: string;
  brand_square_logo_link?: string;
  meta_tags?: string;
  team_name?: string;
  chat_group_id?: string;
  lp_link?: string;
  lp_target_page?: string;
  lp_title?: string;
  lp_subtitle?: string;
  lp_subtitle_postfix?: string;
  dashboard_ui?: string;
  sharemedia_prefix?: string;
  sharemedia_postfix?: string;
  sharesocial_prefix?: string;
  sharesocial_postfix?: string;
  shareknocard_prefix?: string;
  shareknocard_postfix?: string;
  sharenewprospect_prefix?: string;
  sharenewprospect_postfix?: string;
}

export interface AffiliateGroupPricing {
  id: number;
  name: string;
  basic_monthly_price: string;
  pro_monthly_price: string;
  knocard_link_monthly_price: string;
  knocard_spotlight_monthly_price: string;
  knocard_leaders_corner_monthly_price: string;
  pro_plus_monthly_price: string;
  basic_annual_price: string;
  pro_annual_price: string;
  pro_plus_annual_price: string;
  pro_one_price: string;
  knocard_teams_price: string;
  knocard_pro_teams_price: string;
  is_default: number;
}

export interface AffiliateGroupRequest {
  id?: number;
  name: string;
  meta_tags?: string;
  vbcBackground?: File;
  lp_link?: string;
  lp_target_page?: string;
  lp_title?: string;
  lp_subtitle?: string;
  lp_subtitle_postfix?: string;
  dashboard_ui?: string;
  sharemedia_prefix?: string;
  sharemedia_postfix?: string;
  sharesocial_prefix?: string;
  sharesocial_postfix?: string;
  shareknocard_prefix?: string;
  shareknocard_postfix?: string;
  sharenewprospect_prefix?: string;
  sharenewprospect_postfix?: string;
}

export interface GroupSocialPostData {
  id: number;
  group_id?: number;
  title: string;
  image?: null | string;
  description: string;
  created_at: string;
  updated_at: string;
  video?: null | string;
  shares_count?: number;
  reactions_count?: number;
  comments_count?: number;
  views_count?: number;
  deep_link?: null | string;
  status?: number;
  is_included_group_share?: number;
  contents?: GroupSocialPostContents[];
}

export interface GroupSocialPostContents {
  id: number;
  post_id: number;
  group_id: number;
  type: string;
  link: string;
  thumbnail: null | string;
  status: number;
  created_at: string;
  updated_at: string;
  filename: null | string;
  thumb_filename: null | string;
  title: string;
  platform: string;
  position: number;
  description: string;
}

interface InitialState {
  data: AffiliateGroup[];
  affiliateGroupPricing: AffiliateGroupPricing[];
  affiliateGroupMedia: GroupMediaItem[];
  affiliateGroupSocialPosts: GroupSocialPostData[];
  affiliateLandingPageVideos: AffiliateLandingPageWatchVideoItem[];
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  isPricingLoading: boolean;
  isMediaLoading: boolean;
  isSocialPostLoading: boolean;
  isSocialPostLoadMore: boolean;
  socialPostPage: number;
  isCreateEditGroup: boolean;
}

export const initialState: InitialState = {
  data: [],
  affiliateGroupPricing: [],
  affiliateGroupMedia: [],
  affiliateGroupSocialPosts: [],
  affiliateLandingPageVideos: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
  isPricingLoading: false,
  isMediaLoading: false,
  isSocialPostLoading: false,
  isSocialPostLoadMore: false,
  socialPostPage: 1,
  isCreateEditGroup: false,
};
