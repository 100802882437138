import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import {
  MediaPostContent,
  PinPostRequest,
  Post,
  PostObject,
  PostPagination,
  ShareFormInfoResponse,
} from './interface';
import { MediaType, PlatformType } from '../../../enum';
import { config } from '../../../utils/utils';
import { getItem } from '../../../utils/localStorage';
import { toastHandler } from '../../../utils/toastHandler';

export const getPosts = createAsyncThunk(
  'post/getPosts',
  async ({ user_id, page, topic_id, group_id }: PostPagination, { rejectWithValue }) => {
    try {
      var _url = `/api/post?user_id=${user_id}&page=${page}`;
      if (topic_id) _url += `&topic_id=${topic_id}`;
      if (group_id) _url += `&group_id=${group_id}`;
      const response = await axiosInstance.get<Post>(_url);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSinglePost = createAsyncThunk(
  'post/getSinglePost',
  async (postId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<{
        status: string;
        message: string;
        data: PostObject;
      }>(`/api/post/${postId}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGroupPosts = createAsyncThunk(
  'post/getGroupPosts',
  async (
    { user_id, page, group_id, topic_id }: PostPagination,
    { rejectWithValue }
  ) => {
    try {
      var _url = `/api/groupPost?user_id=${user_id}&page=${page}`;
      if (group_id) _url += `&group_id=${group_id}`;
      if (topic_id) _url += `&topic_id=${topic_id}`;
      const response = await axiosInstance.get<Post>(_url);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getShareFormInfo = createAsyncThunk(
  'post/getShareFormInfo',
  async (
    {
      form_code,
      ...rest
    }: { postby_id: number; post_id: number; form_code: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post<ShareFormInfoResponse>(
        `/api/post/share-form-info/${form_code}`,
        rest
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getShareFormAnswer = createAsyncThunk(
  'post/getShareFormAnswer',
  async (
    {
      shareFormId,
      ...rest
    }: {
      shareFormId: number;
      postby_id: number;
      post_id: number;
      form_code: string;
      answer: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/post/share-form-answer/${shareFormId}`,
        rest
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createMediaPost = createAsyncThunk(
  'post/createMediaPost',
  async (
    data: {
      user_id?: number;
      description: string;
      mediaData: MediaPostContent[];
      mediaPostThumb?: File;
      thumbImage?: string;
      thumbText?: string;
      thumbTextColor?: string;
      topic_id?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = new FormData();

      let userToken = getItem(config.AUTH_KEY);

      request.append('api_token', userToken);
      request.append('user_id', `${data.user_id}`);
      request.append('description', data.description);
      if (data.topic_id) {
        request.append('topic_id', `${data.topic_id}`);
      }

      if (data.thumbImage) {
        request.append('thumb_image', `${data.thumbImage}`);
      }      
      if (data.thumbText) {
        request.append('thumb_text', `${data.thumbText}`);
      }
      if (data.thumbTextColor) {
        request.append('thumb_text_color', `${data.thumbTextColor}`);
      }

      var videoIndex = 0;
      var imageIndex = 0;
      var youtubeIndex = 0;
      var vimeoIndex = 0;
      let pdfIndex = 0;
      let assetIndex = 0;
      if (data.mediaPostThumb) {
        request.append(`post_thumbnail`, data.mediaPostThumb);
      }

      data.mediaData.forEach((contentFile: MediaPostContent, index: number) => {
        if (contentFile.platform == PlatformType.youtube) {
          if ((contentFile.thumbnail_link?.trim()?.length ?? 0) > 0) {
            request.append(
              `youtube_thumbnails_link[${youtubeIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          } else if (contentFile.thumbnail != null) {
            request.append(
              `youtube_thumbnails[${youtubeIndex}]`,
              contentFile.thumbnail
            );
          }
          request.append(`youtube[${youtubeIndex}]`, contentFile.link ?? '');
          request.append(`media_positions[${index}]`, contentFile.link ?? '');
          request.append(
            `media_titles[${contentFile.link}]`,
            contentFile.title
          );
          request.append(
            `media_description[${contentFile.link}]`,
            contentFile.description ?? ''
          );
          youtubeIndex++;
        } else if (contentFile.platform == PlatformType.vimeo) {
          if ((contentFile.thumbnail_link?.trim()?.length ?? 0) > 0) {
            request.append(
              `vimeo_thumbnails_link[${vimeoIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          } else if (contentFile.thumbnail != null) {
            request.append(
              `vimeo_thumbnails[${vimeoIndex}]`,
              contentFile.thumbnail
            );
          }
          request.append(`vimeo[${vimeoIndex}]`, contentFile.link ?? '');
          request.append(`media_positions[${index}]`, contentFile.link ?? '');
          request.append(
            `media_titles[${contentFile.link}]`,
            contentFile.title
          );
          request.append(
            `media_description[${contentFile.link}]`,
            contentFile.description ?? ''
          );
          vimeoIndex++;
        }

        if (contentFile.platform == PlatformType.asset) {
          if ((contentFile?.thumbnail_link?.trim().length ?? 0) > 0) {
            request.append(
              `asset_thumbnails_link[${assetIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          }
          request.append(`asset[${assetIndex}]`, `${contentFile.link}`);
          request.append(
            `media_positions[${index}]`,
            `${contentFile.link ?? ''}`
          );
          request.append(
            `media_titles[${contentFile.link}]`,
            `${contentFile.title}`
          );
          request.append(
            `asset_types[${contentFile.link}]`,
            `${contentFile.type}`
          );
          request.append(
            `media_description[${contentFile.link}]`,
            `${contentFile.description}`
          );
          assetIndex++;
        }

        if (contentFile.type == MediaType.image && contentFile.file != null) {
          var imageName = contentFile.file.name;
          request.append(`images[${imageIndex}]`, contentFile.file);
          request.append(`media_positions[${index}]`, imageName);
          request.append(`media_titles[${imageName}]`, contentFile.title);
          request.append(
            `media_description[${imageName}]`,
            contentFile.description ?? ''
          );
          imageIndex++;
        } else if (
          contentFile.type == MediaType.pdf &&
          contentFile.file != null
        ) {
          var pdfName = contentFile.file.name;
          request.append(`pdfs[${pdfIndex}]`, contentFile.file);
          request.append(`media_positions[${index}]`, pdfName);
          request.append(`media_titles[${pdfName}]`, contentFile.title);
          request.append(
            `media_description[${pdfName}]`,
            contentFile.description ?? ''
          );
          pdfIndex++;
        } else if (contentFile.file != null && contentFile.thumbnail != null) {
          var videoName = contentFile.file.name;
          request.append(`videos[${videoIndex}]`, contentFile.file);
          request.append(`thumbnails[${videoIndex}]`, contentFile.thumbnail);
          request.append(`media_positions[${index}]`, videoName);
          request.append(`media_titles[${videoName}]`, contentFile.title);
          request.append(
            `media_description[${videoName}]`,
            contentFile.description ?? ''
          );
          videoIndex++;
        }
      });

      const response = await axiosInstance.post(`/api/post/create`, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editMediaPost = createAsyncThunk(
  'post/editMediaPost',
  async (data: any, { rejectWithValue }) => {
    try {
      const request = new FormData();

      let userToken = getItem(config.AUTH_KEY);

      request.append('api_token', userToken);
      request.append('user_id', data.user_id);
      request.append('description', data.description);

      if (data.topic_id) {
        request.append('topic_id', `${data.topic_id}`);
      }

      data.deleteContents.forEach((item: any, index: number) => {
        request.append(`delete_contents[${item}]`, `${item}`);
      });

      let videoIndex = 0;
      let imageIndex = 0;
      let youtubeIndex = 0;
      let vimeoIndex = 0;
      let pdfIndex = 0;
      let assetIndex = 0;

      if (data.mediaPostThumb) {
        request.append(`post_thumbnail`, data.mediaPostThumb);
      }

      if (data.mediaPostThumbUrl) {
        request.append(`post_thumbnail_url`, data.mediaPostThumbUrl);
      }

      if (data.thumbImage) {
        request.append('thumb_image', `${data.thumbImage}`);
      }      
      if (data.thumbText) {
        request.append('thumb_text', `${data.thumbText}`);
      }
      if (data.thumbTextColor) {
        request.append('thumb_text_color', `${data.thumbTextColor}`);
      }

      data.mediaData.forEach((contentFile: MediaPostContent, index: number) => {
        if (contentFile.content != null) {
          if (contentFile.title.length > 0) {
            request.append(
              `update_content_title[${contentFile.content?.id}]`,
              contentFile.title
            );
          }

          if (contentFile.description && contentFile.description.length > 0) {
            request.append(
              `media_description[${contentFile.content?.id}]`,
              contentFile.description
            );
          }

          if (contentFile.type == MediaType.image && contentFile.file != null) {
            request.append(
              `update_content_file[${contentFile.content?.id}]`,
              contentFile.file
            );
          } else if (
            contentFile.type == MediaType.pdf &&
            contentFile.file != null
          ) {
            request.append(
              `update_content_file[${contentFile.content?.id}]`,
              contentFile.file
            );
          } else if (
            contentFile.type == MediaType.video &&
            contentFile.thumbnail != null
          ) {
            request.append(
              `update_content_file[${contentFile.content?.id}]`,
              contentFile.thumbnail
            );
          }

          request.append(
            `media_positions[${index}]`,
            contentFile.content?.id.toString() ?? ''
          );
        } else {
          if (contentFile.platform == PlatformType.youtube) {
            if (
              contentFile.thumbnail_link != null &&
              (contentFile.thumbnail_link?.length ?? 0) > 0
            ) {
              request.append(
                `youtube_thumbnails_link[${youtubeIndex}]`,
                contentFile.thumbnail_link
              );
            } else if (contentFile.thumbnail != null) {
              request.append(
                `youtube_thumbnails[${youtubeIndex}]`,
                contentFile.thumbnail
              );
            }
            request.append(`youtube[${youtubeIndex}]`, contentFile.link ?? '');
            request.append(`youtube_title[${youtubeIndex}]`, contentFile.title);
            request.append(`media_positions[${index}]`, contentFile.link ?? '');
            youtubeIndex++;
          } else if (contentFile.platform == PlatformType.vimeo) {
            if (
              contentFile.thumbnail_link != null &&
              (contentFile.thumbnail_link?.length ?? 0) > 0
            ) {
              request.append(
                `vimeo_thumbnails_link[${vimeoIndex}]`,
                contentFile.thumbnail_link
              );
            } else if (contentFile.thumbnail != null) {
              request.append(
                `vimeo_thumbnails[${vimeoIndex}]`,
                contentFile.thumbnail
              );
            }
            request.append(`vimeo[${vimeoIndex}]`, contentFile.link ?? '');
            request.append(`vimeo_title[${vimeoIndex}]`, contentFile.title);
            request.append(`media_positions[${index}]`, contentFile.link ?? '');
            vimeoIndex++;
          }

          if (contentFile.platform == PlatformType.asset) {
            if ((contentFile?.thumbnail_link?.trim().length ?? 0) > 0) {
              request.append(
                `asset_thumbnails_link[${assetIndex}]`,
                contentFile.thumbnail_link ?? ''
              );
            }
            request.append(`asset[${assetIndex}]`, `${contentFile.link}`);
            request.append(
              `media_positions[${index}]`,
              `${contentFile.link ?? ''}`
            );
            request.append(
              `asset_title[${assetIndex}]`,
              `${contentFile.title}`
            );
            request.append(
              `asset_types[${contentFile.link}]`,
              `${contentFile.type}`
            );
            request.append(
              `media_description[${contentFile.link}]`,
              `${contentFile.description}`
            );
            assetIndex++;
          }

          if (contentFile.type == MediaType.image && contentFile.file != null) {
            var imageName = contentFile.file.name;
            request.append(`images[${imageIndex}]`, contentFile.file);
            request.append(`media_positions[${index}]`, imageName);
            imageIndex++;
          } else if (
            contentFile.type == MediaType.pdf &&
            contentFile.file != null
          ) {
            var pdfName = contentFile.file.name;
            request.append(`pdfs[${pdfIndex}]`, contentFile.file);
            request.append(`media_positions[${index}]`, pdfName);
            pdfIndex++;
          } else if (
            contentFile.file != null &&
            contentFile.thumbnail != null
          ) {
            var videoName = contentFile.file.name;
            request.append(`videos[${videoIndex}]`, contentFile.file);
            request.append(`thumbnails[${videoIndex}]`, contentFile.thumbnail);
            request.append(`titles[${videoIndex}]`, contentFile.title);
            request.append(`media_positions[${index}]`, videoName);
            videoIndex++;
          }
        }
      });

      const response = await axiosInstance.post(
        `/api/post/edit/${data.postId}`,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMediaPost = createAsyncThunk(
  'delete/deleteMediaPost',
  async (data: any, { rejectWithValue }) => {
    try {
      let userToken = getItem(config.AUTH_KEY);

      const body: any = {
        api_token: userToken,
      };
      const response = await axiosInstance.delete(
        `/api/post/${data.postId}`,
        body
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPinedPosts = createAsyncThunk(
  'post/getPinedPosts',
  async ({ user_id, page }: PostPagination, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<Post>(
        `/api/post/pin?user_id=${user_id}&page=${page}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const pinMediaPost = createAsyncThunk(
  'post/pinMediaPost',
  async ({ postId, isPin }: PinPostRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<Post>(
        isPin ? `/api/post/pin/${postId}` : `/api/post/unpin/${postId}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const toggleMediaPostStatus = createAsyncThunk(
  'post/toggleMediaPostStatus',
  async ({ postId }: {postId: number}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<Post>(`/api/post/toggle-post-status/${postId}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);