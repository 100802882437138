import React, { useEffect, useState } from 'react';
import sidebarConfig, {
  knoCardSocialConnectSidebarConfig,
} from './SidebarConfig';
import styles from '../../styles/HamburgerMenuModal.module.css';
import { AppDispatch, useAppSelector } from '../../store';
import { AppColors, config } from '../../utils/utils';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkIsKnoCardLinkPurchased,
  checkIsKnoCardSocialConnectUserPlan,
  checkIsProOneUser,
  checkIsProProOrProOneOrProPlusUser,
  checkIsProUser,
  checkIsTeamsOrProTeamsUser,
} from '../../utils/checkPro';
import { Box, Badge, Chip, Typography, Divider, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { encryptString } from '../../utils/url';
import { getItem, removeItem } from '../../utils/localStorage';
import { getPlanLabel, showSuccessMessage } from '../../utils/utilMethods';
import { logoutUser } from '../../store/auth/api';
import { useDispatch } from 'react-redux';
import { logoutUserFromLocal } from '../../store/auth/authSlice';
import { findAffiliateGroup } from '../../store/AffiliateGroup/api';

const UserDashboardSidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const navLink = document.querySelectorAll('.nav__link');
  const { user } = useAppSelector((state) => state?.auth);
  const encriptedToken = encryptString(getItem(config.AUTH_KEY));
  const external_link = user?.external_pages?.link;
  const show_profile_picture =
    useAppSelector((state) => state?.auth?.user?.show_profile_picture) ?? 0;
  const isProUser = checkIsProUser(user);
  const isTeamsUser = checkIsTeamsOrProTeamsUser(user);
  const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(user);
  const chatChannels = useAppSelector((state) => state?.chat?.chatChannels);
  const [showLeaderCorner, setShowLeaderCorner] = useState(false);

  const { data: affiliateGroupList, isLoading: isAffiliateGroupLoading } =
    useAppSelector((state) => state?.affiliateGroup);

  useEffect(() => {
    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }
  }, []);

  useEffect(() => {
    if (affiliateGroupList.length > 0) {
      affiliateGroupList.forEach((item) => {
        if (`${item.owner_name}` == user?.username) {
          setShowLeaderCorner(true);
        }
      });
    }
  }, [affiliateGroupList]);

  function linkColor(e: Event) {
    navLink.forEach((link) => link.classList.remove('active-link'));
    // this.classList.add('active-link');
  }
  navLink.forEach((link) => link.addEventListener('click', linkColor));

  function copyWebAppLink() {
    const vbcLink = `${config.VBC_URL}/${user?.username}`;
    navigator.clipboard.writeText(vbcLink);
    showSuccessMessage('Web App link copied successfully');
  }

  function openWebAppLink() {
    const vbcLink = `${config.VBC_URL}/${user?.username}`;
    window.open(`${vbcLink}`, '_blank') ||
      window.location.replace(`${vbcLink}`);
  }

  function copyAffiliateLink() {
    const affiliateLink = `${config.VBC_URL}/affiliate/${user?.username}`; // `${user?.affiliate_link}`;
    navigator.clipboard.writeText(affiliateLink);
    showSuccessMessage('Affiliate link copied successfully');
  }

  function copyLandingPageLink() {
    const landingPageLink = `${config.VBC_URL}/referral/1?${config.REFER_KEY}=${user?.username}`;
    navigator.clipboard.writeText(landingPageLink);
    showSuccessMessage('Landing page link copied successfully');
  }

  function copyMbxPageLink() {
    const landingPageLink = `${config.VBC_URL}/referral/mbx?${config.REFER_KEY}=mbx`;
    navigator.clipboard.writeText(landingPageLink);
    showSuccessMessage('MBX page link copied successfully');
  }

  function openaffiliateDashboard() {
    const affiliateDashboardUrl = `${config.AFFILIATE_DASHBOARD_URL}/${encriptedToken}`;
    window.open(`${affiliateDashboardUrl}`, '_blank') ||
      window.location.replace(`${affiliateDashboardUrl}`);
  }

  function openaCorporateReport() {
    navigate('/dashboard/corporate-report');
  }

  const handleOpenSubscription = () => {
    navigate('/subscription');
  };

  let isActiveMenu = (routePath: string): boolean => {
    return (
      routePath == location.pathname ||
      ('/dashboard/add-preferred-partner' == location.pathname &&
        routePath == '/dashboard/preferred-partners') ||
      (location.pathname.startsWith('/dashboard/edit-preferred-partner/') &&
        routePath == '/dashboard/preferred-partners') ||
      ('/dashboard/add-business-social' == location.pathname &&
        '/dashboard/business-social' == routePath) ||
      (location.pathname.startsWith('/dashboard/edit-business-social/') &&
        '/dashboard/business-social' == routePath)
    );
  };

  const getUnreadMessageCount = () => {
    return chatChannels.reduce(
      (total, item) =>
        (total +=
          item?.last_message?.from != user?.id
            ? item?.last_message?.unread ?? 0
            : 0),
      0
    );
  };

  const handleSignOut = async (_: React.MouseEvent<HTMLElement>) => {
    localStorage.clear();
    await dispatch(logoutUserFromLocal());
    await dispatch(logoutUser());
    removeItem(config.INFO);
    navigate(`/`);
  };

  return (
    <>
      <nav
        className='nav'
        style={{
          marginTop: '110px',
          height: '100vh',
          overflow: 'auto',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        <Box
          className={styles.sideBar}
          sx={{
            background: AppColors.whiteColor,
            pl: 2,
            pr: 2,
            pt: 2,
            gap: 1,
            borderRadius: '25px',
            position: 'relative',
          }}
        >
          <Box
            className={styles.probadge}
            sx={{
              background: isProUser
                ? AppColors.yellowColor
                : AppColors.greenColor,
            }}
            onClick={
              user?.current_subscription?.code?.code != config.PRO_1
                ? handleOpenSubscription
                : undefined
            }
          >
            <Box
              component={'img'}
              src='/static/images/pro_black.svg'
              alt='Pro'
              mr={0.5}
            />

            {user?.current_subscription?.code?.code == config.PRO_1 &&
            isProUser ? (
              <Typography className={styles.proBadgeText}>
                {getPlanLabel(config.PRO_1)}
              </Typography>
            ) : user?.current_subscription?.code?.code == config.PRO_PRO &&
              isProUser ? (
              <Typography className={styles.proBadgeText}>
                {getPlanLabel(config.PRO_PRO)}
              </Typography>
            ) : user?.current_subscription?.code?.code == config.PRO_BASIC &&
              isProUser ? (
              <Typography className={styles.proBadgeText}>
                {getPlanLabel(config.PRO_BASIC)}
              </Typography>
            ) : user?.current_subscription?.code?.code == config.PRO_PLUS &&
              isProUser ? (
              <Typography className={styles.proBadgeText}>
                {getPlanLabel(config.PRO_PLUS)}
              </Typography>
            ) : user?.current_subscription?.code?.code ==
                config.PRO_FREE_TRIAL && isProUser ? (
              <Typography className={styles.userBadgeText}>
                {getPlanLabel(config.PRO_FREE_TRIAL)}
              </Typography>
            ) : user?.current_subscription?.code?.code ==
              config.KNOCARD_SOCIAL_CONNECT ? (
              <Typography>
                {getPlanLabel(config.KNOCARD_SOCIAL_CONNECT)}
              </Typography>
            ) : user?.current_subscription?.code?.code ==
              config.KNOCARD_TEAMS ? (
              <Typography>{getPlanLabel(config.KNOCARD_TEAMS)}</Typography>
            ) : user?.current_subscription?.code?.code ==
              config.KNOCARD_PRO_TEAMS ? (
              <Typography>{getPlanLabel(config.KNOCARD_PRO_TEAMS)}</Typography>
            ) : (
              <Typography>{getPlanLabel(config.FREE)}</Typography>
            )}
          </Box>

          <Box className={styles.profileContainer}>
            <Box className={styles.profileImageNameContainer}>
              <img
                src={
                  show_profile_picture
                    ? user?.profile_picture ??
                      '/static/images/placeholder_profile.png'
                    : '/static/images/placeholder_hide_profile.png'
                }
                alt='profile'
                className={styles.profileImage}
              />
              <Box className={styles.profileNameContainer}>
                <Box
                  className={styles.welcomeText}
                  sx={{ color: AppColors.grayThreeColor }}
                >
                  Welcome Back
                </Box>
                <Box
                  className={styles.profileName}
                  sx={{ color: AppColors.blackColor }}
                >
                  {user?.name ?? ''}
                </Box>
                <Box mt={1}>
                  <Button
                    sx={{
                      textTransform: 'capitalize',
                      color: AppColors.whiteColor,
                      background: AppColors.primaryColor,
                      borderRadius: '40px',
                      paddingLeft: 2,
                      paddingRight: 2,
                      ':hover': {
                        color: AppColors.primaryColor,
                      },
                    }}
                    endIcon={
                      <ContentCopyIcon
                        sx={{
                          float: 'right',
                        }}
                      />
                    }
                    onClick={copyWebAppLink}
                  >
                    Your KnoCard URl
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* <Box component={'div'}>

              <Box component={'div'}>
                {user?.current_subscription?.code?.code == config.PRO_1 &&
                  isProUser ? (
                  <Box className={styles.packageTagPro}>
                    <span className={styles.userBadgeText}>
                      {getPlanLabel(config.PRO_1)}
                    </span>
                    <img
                      className={styles.badgeIcon}
                      src='/static/images/crown.svg'
                      alt='userBadge'
                    />
                  </Box>
                ) : user?.current_subscription?.code?.code == config.PRO_PRO &&
                  isProUser ? (
                  <Box className={styles.packageTagPro}>
                    <span className={styles.userBadgeText}>
                      {getPlanLabel(config.PRO_PRO)}
                    </span>
                  </Box>
                ) : user?.current_subscription?.code?.code ==
                  config.PRO_BASIC && isProUser ? (
                  <Box className={styles.packageTagPro}>
                    <span className={styles.userBadgeText}>
                      {getPlanLabel(config.PRO_BASIC)}
                    </span>
                  </Box>
                ) : user?.current_subscription?.code?.code == config.PRO_PLUS &&
                  isProUser ? (
                  <Box className={styles.packageTagPro}>
                    <span className={styles.userBadgeText}>
                      {getPlanLabel(config.PRO_PLUS)}
                    </span>
                  </Box>
                ) : user?.current_subscription?.code?.code ==
                  config.PRO_FREE_TRIAL && isProUser ? (
                  <Box className={styles.packageTagPro}>
                    <span className={styles.userBadgeText}>
                      {getPlanLabel(config.PRO_FREE_TRIAL)}
                    </span>
                  </Box>
                ) : user?.current_subscription?.code?.code ==
                  config.KNOCARD_SOCIAL_CONNECT ? (
                  <Box
                    className={styles.packageTagFree}
                    onClick={handleOpenSubscription}
                  >
                    <span>{getPlanLabel(config.KNOCARD_SOCIAL_CONNECT)}</span>
                  </Box>
                ) : (
                  <Box
                    className={styles.packageTagFree}
                    onClick={handleOpenSubscription}
                  >
                    <span>{getPlanLabel(config.FREE)}</span>
                  </Box>
                )}
              </Box>

              {user?.current_subscription?.code?.code != config.PRO_1 && (
                <Box component={'div'}>
                  <Box
                    className={styles.packageTagFree}
                    onClick={handleOpenSubscription}
                    sx={{ mt: 2 }}
                  >
                    <span>Upgrade to Pro</span>
                  </Box>
                </Box>
              )}
            </Box> */}
          </Box>
          <Divider
            sx={{
              backgroundColor: AppColors.strokeColor,
              width: '100%',
              height: '1px',
              mt: 1,
              mb: 1.5,
            }}
          />

          {isProUser && (
            <Box
              sx={{
                minHeight: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pl: 1,
                pr: 1,
                color: AppColors.blackColor,
                background: AppColors.yellowColor,
                width: '100%',
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              onClick={openaffiliateDashboard}
            >
              <Typography sx={{ textAlign: 'center', pt: 0.8, pb: 0.8 }}>
                {/* Affiliate Dash */}
                Promoter Dash
              </Typography>
            </Box>
          )}
          {/* {isProUser && (<Box sx={{
            minHeight: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            color: AppColors.whiteColor,
            background: AppColors.primaryColor, width: "100%", borderRadius: '12px', mt: 0.3, mb: "3px", cursor: "pointer"
          }} onClick={() => {
            navigate("/dashboard/promoter-dash");
          }}>
            <Typography sx={{ textAlign: "center", pt: 0.8, pb: 0.8 }} >Promoter Dash</Typography>
          </Box>)} */}

          {/* {showLeaderCorner && ( */}
          {/* {user?.username == user?.affiliate_group?.owner_name && ( */}
          {(user?.affiliate_group || isTeamsUser) && (
            <Box
              sx={{ width: '100%', position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                if (user?.username == user?.affiliate_group?.owner_name || isTeamsUser)
                  navigate('/dashboard/leader-corner');
                else navigate('/dashboard/group-training-media');
              }}
            >
              <Box
                sx={{
                  minHeight: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pl: 1,
                  pr: 1,
                  color: AppColors.whiteColor,
                  background: AppColors.primaryColor,
                  width: '100%',
                  borderRadius: '12px',
                  mt: 0.3,
                  mb: '3px',
                }}
              >
                <Typography sx={{ textAlign: 'center', pt: 0.8, pb: 0.8 }}>
                  Leaders Corner
                </Typography>
              </Box>
              <Box
                sx={{
                  color: AppColors.blackColor,
                  background: AppColors.yellowColor,
                  width: 'auto',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: -9,
                  left: '50%',
                  pl: 2,
                  pr: 2,
                  transform: 'translateX(-50%)',
                }}
              >
                <Typography sx={{ textAlign: 'center' }}>Beta</Typography>
              </Box>
            </Box>
          )}

          {/* {user?.username == user?.affiliate_group?.owner_name && (
            <Box
              sx={{
                minHeight: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pl: 1,
                pr: 1,
                mt: 1,
                color: AppColors.blackColor,
                background: AppColors.yellowColor,
                borderRadius: '12px',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={openaCorporateReport}
            >
              <Typography sx={{ textAlign: 'center', pt: 0.8, pb: 0.8 }}>
                Corporate Report
              </Typography>
            </Box>
          )} */}

          <Divider
            sx={{
              backgroundColor: AppColors.strokeColor,
              width: '100%',
              height: '1px',
              mt: 1,
              mb: 1,
            }}
          />

          <Box className={styles.linksContainer}>
            {(isKnoCardSocialConnectUser
              ? knoCardSocialConnectSidebarConfig
              : sidebarConfig
            ).map((item, idx) => {
              let isActiveMenuItem = isActiveMenu(item.path);

              if (
                !checkIsKnoCardLinkPurchased(user) &&
                item.path == '/dashboard/knocard-link'
              ) {
                return <></>;
              } else if (
                !checkIsProProOrProOneOrProPlusUser(user) &&
                item.path == '/dashboard/custom-landing-page'
              ) {
                return <></>;
              }

              // return item.title === 'Engage' || item.title == 'Pipeline' ? (
              //   <Box
              //     key={`user-dashoard-sidebar-menu-${idx}`}
              //     className={`${styles.linkContainerEngage}  ${isActiveMenuItem ? styles.linkContainerActive : ''
              //       }`}
              //     onClick={() => {
              //       navigate(item.path);
              //     }}
              //   >
              //     <Box
              //       className={styles.linkIconContainerEngage}
              //       sx={{ borderRadius: '15px', position: 'relative' }}
              //     >
              //       {!checkIsProProOrProOneOrProPlusUser(user) && (
              //         <img
              //           src={'/static/images/lock-yellow.svg'}
              //           alt={item.title}
              //           style={{
              //             position: 'absolute',
              //             height: '30px',
              //             left: 20,
              //           }}
              //           className={styles.linkIcon}
              //         />
              //       )}
              //       <img
              //         src={'/static/images/knocard_pipeline.png'}
              //         style={{ width: '100%' }}
              //       />
              //       {/* <Chip
              //         label="Beta"
              //         sx={{ ml: 1, color: "yellow" }}
              //       /> */}
              //     </Box>
              //   </Box>
              // ) : (
              return (
                <Box
                  key={`user-dashoard-sidebar-menu-${idx}`}
                  className={`${styles.linkContainer}  ${
                    isActiveMenuItem ? styles.linkContainerActive : ''
                  }`}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <Box className={styles.linkIconContainer}>
                    <Badge
                      badgeContent={
                        item.title == 'Messaging' ? getUnreadMessageCount() : 0
                      }
                      color='error'
                    >
                      <img
                        src={isActiveMenuItem ? item.activeIcon : item.icon}
                        alt={item.title}
                        className={styles.linkIcon}
                      />
                    </Badge>

                    {/* {!checkIsProOneUser(user) &&
                    [sidebarConfig[5].title].includes(item.title) && (
                      <img
                        src={'/static/images/lock-yellow.svg'}
                        alt={item.title}
                        style={{
                          position: 'absolute',
                          height: '30px',
                          // left: 20,
                        }}
                        className={styles.linkIcon}
                      />
                    )} */}

                    {/* {!isProUser && !isKnoCardSocialConnectUser &&
                    [
                      sidebarConfig[0].title,
                      sidebarConfig[3].title,
                      sidebarConfig[9].title,
                      sidebarConfig[7].title,
                    ].includes(item.title) && (
                      <img
                        src={'/static/images/lock-yellow.svg'}
                        alt={item.title}
                        style={{ position: 'absolute', height: '30px' }}
                        className={styles.linkIcon}
                      />
                    )} */}
                  </Box>
                  <Typography
                    className={`${styles.linkText} ${
                      isActiveMenuItem ? styles.linkTextActive : ''
                    }`}
                    sx={{ color: AppColors.grayTwoColor }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
            <Box
              key={`user-dashoard-sidebar-menu-signout`}
              className={`${styles.linkContainer}`}
              onClick={handleSignOut}
              sx={{ display: { lg: 'none', xs: 'flex' } }}
            >
              <Box className={styles.linkIconContainer}>
                <Box
                  component={'img'}
                  src={'/static/images/logout.svg'}
                  alt={'Sign Out'}
                  sx={{ width: '25px' }}
                  className={styles.linkIcon}
                />
              </Box>
              <Typography
                className={`${styles.linkText}`}
                sx={{ color: AppColors.grayTwoColor }}
              >
                Sign Out
              </Typography>
            </Box>
          </Box>
        </Box>
      </nav>
    </>
  );
};

export default UserDashboardSidebar;
