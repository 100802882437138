import {
  Box,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from '../styles/Reports.module.css';
import userInfoStyles from "../styles/HamburgerMenuModal.module.css";
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { activityCards } from '../constant';
import { useEffect, useRef, useState } from 'react';
import { getAllReportTotal } from '../store/reports/api';
import { AppColors, config } from '../utils/utils';
import NewProspects from '../components/Reports/NewProspects';
import PageViews from '../components/Reports/PageViews';
import WebAppShare from '../components/Reports/WebAppShare';
import Referrals from '../components/Reports/Referrals';
import ShareSocialPosts from '../components/Reports/ShareSocialPosts';
import MediaShares from '../components/Reports/MediaShares';
import VideoViews from '../components/Reports/VideoViews';
import { getItem, removeItem } from '../utils/localStorage';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';
import { encryptString } from '../utils/url';
import AffiliateLandingPage from '../components/Reports/AffiliateLandingPage';
import { formatReportCount, getPlanLabel, showSuccessMessage } from '../utils/utilMethods';
import { ReportCountItem } from '../components/Reports/ReportContItem';

const Reports = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);

  const encriptedToken = encryptString(getItem(config.AUTH_KEY));

  const { allReportSummary } = useAppSelector((state) => state?.reports);
  const [activeBtn, setActiveBtn] = useState('lead');
  const navigate = useNavigate();
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Month');
  const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

  const isProUser = checkIsProUser(user);
  const reportDetailRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {

    if (isProUser) {
      dispatch(getAllReportTotal({ userId: (user?.id ?? 0) })).unwrap();
    }
    let redirectUrl = getItem(config.SIGNUP_REDIRECT_KEY);
    if (redirectUrl.length > 0) {
      removeItem(config.SIGNUP_REDIRECT_KEY);
      navigate(`/${redirectUrl}`)
    }

  }, []);

  useEffect(() => {
    scrollToReportDetail();
  }, [activeBtn]);

  const scrollToReportDetail = () => {
    reportDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };

  const getReportSummaryCount = (title: string, index: number): { count: number, previousCount: number } => {
    if (title === 'New Prospects') {
      return getReportLeadData(index);
    }

    if (title === 'Page Views') {
      return getReportPageViewData(index);
    }

    if (title === 'Video Views') {
      return getReportMediaVideoViewData(index);
    }

    if (title === 'Media Shares') {
      return getReportMediaShareData(index);
    }

    if (title === 'Personal Shares') {
      return getWebAppShareData(index);
    }

    if (title === 'Social Posts') {
      return getShareSocialPostsData(index);
    }

    if (title === 'Referrals') {
      return getReferralsData(index);
    }

    if (title === 'Landing Page') {
      return getLandingPageData(index);
    }

    return { count: 0, previousCount: 0 };
  };

  const getReportLeadData = (index: number): { count: number, previousCount: number } => {

    switch (index) {
      case 0:
        return { count: allReportSummary?.contactToday || 0, previousCount: allReportSummary?.contactYesterday || 0 };
      case 1:
        return { count: allReportSummary?.contactWeek || 0, previousCount: allReportSummary?.contactPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.contactMonth || 0, previousCount: allReportSummary?.contactPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.contactAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportPageViewData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.viewsToday || 0, previousCount: allReportSummary?.viewsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.viewsWeek || 0, previousCount: allReportSummary?.viewsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.viewsMonth || 0, previousCount: allReportSummary?.viewsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.viewsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportMediaShareData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareMediaToday || 0, previousCount: allReportSummary?.shareMediaYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareMediaWeek || 0, previousCount: allReportSummary?.shareMediaPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareMediaMonth || 0, previousCount: allReportSummary?.shareMediaPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareMediaAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReportMediaVideoViewData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.viewVideoMediaPostToday || 0, previousCount: allReportSummary?.viewVideoMediaPostYesterday || 0 };
      case 1:
        return { count: allReportSummary?.viewVideoMediaPostWeek || 0, previousCount: allReportSummary?.viewVideoMediaPostPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.viewVideoMediaPostMonth || 0, previousCount: allReportSummary?.viewVideoMediaPostPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.viewVideoMediaPostAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getWebAppShareData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.webAppShareToday || 0, previousCount: allReportSummary?.webAppShareYesterday || 0 };
      case 1:
        return { count: allReportSummary?.webAppShareWeek || 0, previousCount: allReportSummary?.webAppSharePreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.webAppShareMonth || 0, previousCount: allReportSummary?.webAppSharePreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.webAppShareAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getReferralsData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.referralsToday || 0, previousCount: allReportSummary?.referralsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.referralsWeek || 0, previousCount: allReportSummary?.referralsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.referralsMonth || 0, previousCount: allReportSummary?.referralsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.referralsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getLandingPageData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareAffiliateLandingPageToday || 0, previousCount: allReportSummary?.shareAffiliateLandingPageYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareAffiliateLandingPageWeek || 0, previousCount: allReportSummary?.shareAffiliateLandingPagePreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareAffiliateLandingPageMonth || 0, previousCount: allReportSummary?.shareAffiliateLandingPagePreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareAffiliateLandingPageAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const getShareSocialPostsData = (index: number): { count: number, previousCount: number } => {
    switch (index) {
      case 0:
        return { count: allReportSummary?.shareSocialPostsToday || 0, previousCount: allReportSummary?.shareSocialPostsYesterday || 0 };
      case 1:
        return { count: allReportSummary?.shareSocialPostsWeek || 0, previousCount: allReportSummary?.shareSocialPostsPreviousWeek || 0 };
      case 2:
        return { count: allReportSummary?.shareSocialPostsMonth || 0, previousCount: allReportSummary?.shareSocialPostsPreviousMonth || 0 };
      case 3:
        return { count: allReportSummary?.shareSocialPostsAllTime || 0, previousCount: 0 };
    }
    return { count: 0, previousCount: 0 };
  };

  const handleOpenSubscription = () => {
    navigate('/subscription');
  }



  function copyWebAppLink() {
    const vbcLink = `${config.VBC_URL}/${user?.username}`;
    navigator.clipboard.writeText(vbcLink);
    showSuccessMessage('Web App link copied successfully');
  }

  function copyAffiliateLink() {
    const affiliateLink = `${config.VBC_URL}/affiliate/${user?.username}`;//`${user?.affiliate_link}`;
    navigator.clipboard.writeText(affiliateLink);
    showSuccessMessage('Affiliate link copied successfully');
  }

  function copyLandingPageLink() {
    const landingPageLink = `${config.VBC_URL}/referral/1?${config.REFER_KEY}=${user?.username}`;
    navigator.clipboard.writeText(landingPageLink);
    showSuccessMessage('Landing page link copied successfully');
  }

  function copyMbxPageLink() {
    const landingPageLink = `${config.VBC_URL}/referral/mbx?${config.REFER_KEY}=mbx`;
    navigator.clipboard.writeText(landingPageLink);
    showSuccessMessage('MBX page link copied successfully');
  }

  function openaffiliateDashboard() {
    const affiliateDashboardUrl = `${config.AFFILIATE_DASHBOARD_URL}/${encriptedToken}`;
    window.open(`${affiliateDashboardUrl}`, "_blank") || window.location.replace(`${affiliateDashboardUrl}`);
  }

  return (
    <>
      <Page title='Reports | KnoCard' showAppBar={true}>
        <Container maxWidth='xl' sx={{ mt: 3 }}>

          <Grid container>
            <Grid item xs={12}>
              <Box >
                <Box className={styles.activityContainerHeader}>
                  <Typography className={styles.heading} sx={{ width: { xs: '100%', color: AppColors.blackColor } }}  >Statistics</Typography>
                  <Box className={styles.btnContainer}>

                    <Select
                      id='time-filter-select'
                      value={selectedTimeFilter}
                      onChange={handleChangeTimeFilter}
                      IconComponent={KeyboardArrowDownIcon}
                      // sx={{
                      //   boxShadow: 'none',
                      //   '.MuiOutlinedInput-notchedOutline': {
                      //     border: 0,
                      //   },
                      // }}
                      className={styles.activityFilterDropDown}
                    >
                      {timeFilterList.map((val: any) => {
                        return (
                          <MenuItem value={val}>
                            <Box className={styles.activityFilterDropDownText}>
                              {val}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
                <Grid container gap={{ xs: 1, sm: 2 }} justifyContent={'center'} sx={{ p: { md: 2, xs: 0 }, backgroundColor: { md: AppColors.whiteColor, xs: 'unset' }, borderRadius: "10px", mt: 2, position: 'relative' }}>
                  {activityCards?.length &&
                    activityCards.map((card) => {
                      let reportData = getReportSummaryCount(card.title, timeFilterList.indexOf(selectedTimeFilter));
                      return (<Grid item md={2.7} sm={5.8} xs={5.6}>
                        <Box onClick={() => (isProUser) ? setActiveBtn(card.code) : handleOpenSubscription()}>
                          <ReportCountItem title={card.title} previousReport={reportData.previousCount || 0} currentReport={(reportData.count || 0)} isProUser={isProUser} reportType={selectedTimeFilter} />
                        </Box>
                      </Grid>);
                    })}
                  <Box ref={reportDetailRef} sx={{ position: 'absolute', bottom: 100 }}></Box>
                </Grid>

              </Box>
            </Grid>
            <Grid item xs={12}>
              {isProUser && <Box sx={{ gap: 0, mt: 4 }}>
                {activeBtn === 'lead' && (
                  <NewProspects selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'knocard' && (
                  <PageViews selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'webAppShare' && (
                  <WebAppShare selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'referrals' && (
                  <Referrals selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'shareSocialPosts' && (
                  <ShareSocialPosts selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'mediaVideoViewCount' && (
                  <VideoViews selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'mediaShare' && (
                  <MediaShares selectedTimeFilter={selectedTimeFilter} />
                )}

                {activeBtn === 'group_landing_page' && (
                  <AffiliateLandingPage selectedTimeFilter={selectedTimeFilter} />
                )}
              </Box>}
            </Grid>
          </Grid>

        </Container >
      </Page >
    </>
  );
};
export default Reports;
