import { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from '../../styles/Reports.module.css';
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getAffiliateGroupMedia } from '../../store/AffiliateGroup/api';
import { AppColors } from '../../utils/utils';
import { formatNumber } from '../../utils/utilMethods';
import {
  convertToTimeString,
  dateFormate,
  formatTime,
} from '../../utils/format';
import { GroupMediaItem } from '../../store/corporateReporting/api/interface';
import { getMediaPostReportCount } from '../../store/corporateReporting/api';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { IGroupReportTrackingPageParams } from '../../enum';

const GroupMediaVideoTrackingView = () => {
  const navigate = useNavigate();
  const params = useParams<IGroupReportTrackingPageParams>();
  const observer = useRef<IntersectionObserver>();
  const dispatch = useAppDispatch();

  const scrollRef = useRef<any>();
  const countScrollRef = useRef<null | HTMLDivElement>(null);

  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const isLoading = useAppSelector(
    (state) => state?.affiliateGroup?.isMediaLoading
  );
  const posts =
    useAppSelector((state) => state?.affiliateGroup.affiliateGroupMedia) ?? [];

  const postWatchCount = useAppSelector(
    (state) => state?.corporateReporting.postWatchCount
  );

  const [selectedPost, setSelectedPost] = useState<GroupMediaItem | undefined>(
    undefined
  );

  const [selectedTimeFilter, setSelectedTimeFilter] = useState(
    params.dayfilter ?? 'Month'
  );
  const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

  useEffect(() => {
    if (userId) {
      if (posts.length === 0) {
        dispatch(
          getAffiliateGroupMedia({
            groupId: userInfo?.affiliate_group?.id!,
          })
        );
      }
    }
  }, [userId]);

  useEffect(() => {
    if (selectedPost) {
      dispatch(
        getMediaPostReportCount({
          postId: selectedPost.id,
          days: getDaysFromFilter(selectedTimeFilter),
        })
      );
    }
  }, [selectedPost, selectedTimeFilter]);

  useEffect(() => {
    if (posts && posts.length > 0) {
      posts.forEach((post, idx) => {
        if (
          post.contents &&
          (post.contents?.length ?? 0) > 0 &&
          post.contents[0].type == 'video'
        ) {
          getVideoDuration(post?.contents[0]?.link!, `post-duration-${idx}`);
        }
      });
    }
  }, [posts]);

  const handleOnCommandCenterClicked = () => {
    navigate('/dashboard/leader-corner');
  };

  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };

  const scrollToCount = () => {
    countScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getVideoDuration = (link: string, elementId: string): string => {
    const media = new Audio(link);
    media.onloadeddata = (e) => {
      const section = document.querySelector(`#${elementId}`) as HTMLElement;
      section.innerText = convertToTimeString(media.duration ?? 0);
    };
    return '';
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        className={styles.mainContentBody}
        my={2}
      >
        <Box
          display='flex'
          alignItems={'center'}
          gap={1}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon sx={{ color: AppColors.blackColor }} />
          {/* <Typography>Back to Top</Typography> */}
        </Box>
        <Stack
          direction={{ sm: 'row', xs: 'column' }}
          display={'flex'}
          justifyContent={'space-between'}
          alignContent={'center'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box width={{ sm: '70%', xs: '100%' }}>
            <Typography sx={{ fontSize: '2rem', fontWeight: '500' }}>
              KnoCard Video Tracking
            </Typography>
            <Typography sx={{ fontSize: '1.2rem', pt: 1 }}>
              Monitor all your media in real time across your entire network.
              Instantly see which videos are performing and which need
              improvement. With real-time data, you can act fast to boost sales
              success-no more waiting on A/B testing!
            </Typography>
          </Box>
          <Box width={{ xs: '100%', sm: 'auto' }} mt={{ xs: 1, sm: 0 }}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignContent={'center'}
              alignItems={'center'}
              onClick={handleOnCommandCenterClicked}
              px={3}
              py={0.5}
              sx={{
                cursor: 'pointer',
                backgroundImage:
                  'url("/static/images/tutorial_background.svg")',
                borderRadius: '8px',
              }}
            >
              <Box
                component={'img'}
                src='/static/images/command_center_img.png'
                height={'60px'}
                pb={1}
              />
              <Typography sx={{ fontSize: '0.8rem', color: 'white' }}>
                Command Center
              </Typography>
            </Box>

            <Box className={styles.btnContainer} mt={2} width={'100%'}>
              <Select
                id='time-filter-select'
                value={selectedTimeFilter}
                onChange={handleChangeTimeFilter}
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  backgroundColor: AppColors.primaryColor,
                  borderRadius: '12px',
                  color: 'white',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '& svg': {
                    color: '#ffffff',
                  },
                }}
                className={styles.activityFilterDropDown}
              >
                {timeFilterList.map((val: any) => {
                  return (
                    <MenuItem value={val}>
                      <Box className={styles.activityFilterDropDownText}>
                        {val}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Stack>
        <Grid container direction={'row'} alignItems={'center'}>
          <Grid item xs={12} md={12}>
            <Stack
              id={`post-items`}
              direction={'row'}
              display={'flex'}
              // sx={{ overflowY: 'hidden' }}
              ref={scrollRef}
            >
              <Grid
                container
                display={'flex'}
                justifyContent={'start'}
                // className={styles.mediaContainerGrid}
                gap={0.5}
              >
                {posts?.map((post, idx) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={3.5}
                      md={2.95}
                      lg={1.95}
                      key={`post-${idx}`}
                      className={styles.mediaContainer}
                    >
                      <RenderCompanyMediaFeed post={post} />

                      <Grid
                        container
                        mt={1}
                        display={'flex'}
                        alignContent={'center'}
                        flexDirection={'column'}
                      >
                        {post.contents &&
                          (post.contents.length || 0) > 0 &&
                          post.contents[0].type == 'video' && (
                            <Grid
                              item
                              display={'flex'}
                              direction={'row'}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <AccessTimeIcon
                                sx={{ height: '15px', color: 'black' }}
                              />

                              <Typography
                                id={`post-duration-${idx}`}
                                component={'p'}
                                variant='caption'
                                color={'black'}
                              ></Typography>
                            </Grid>
                          )}
                        <Grid item>
                          <Typography
                            component={'p'}
                            variant='body1'
                            color={'black'}
                            textAlign={'center'}
                          >
                            {(post.description || '').trim().length > 0 ? (
                              post.description
                            ) : (
                              <>&nbsp;</>
                            )}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            component={'p'}
                            variant='caption'
                            color={'gray'}
                            mb={0.5}
                            textAlign={'center'}
                          >
                            {`Uploaded Date: ${dateFormate(
                              post.created_at || '',
                              'MM/DD/YY'
                            )}`}
                            <br />
                            {`Last Modified: ${dateFormate(
                              post.updated_at || '',
                              'MM/DD/YY'
                            )}`}
                          </Typography>
                        </Grid>

                        <Button
                          variant={'contained'}
                          sx={{
                            textTransform: 'unset',
                            borderRadius: '20px',
                            width: '80%',
                          }}
                          onClick={() => {
                            setSelectedPost(post);
                            scrollToCount();
                          }}
                        >
                          <Typography variant='caption'>
                            Request Report
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        {isLoading && (
          <Box display='flex' justifyContent='center' mt={2}>
            <CircularProgress />
          </Box>
        )}
        {selectedPost && (
          <>
            <Grid
              container
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={5}
              my={2}
              ref={countScrollRef}
            >
              <Grid item>
                <Box>
                  <RenderCompanyMediaFeed post={selectedPost} width='200px' />
                  <Typography
                    component={'p'}
                    variant='body1'
                    color={'black'}
                    textAlign={'center'}
                    mt={1}
                  >
                    {(selectedPost.description || '').trim().length > 0 ? (
                      selectedPost.description
                    ) : (
                      <>&nbsp;</>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item textAlign={'center'}>
                <Typography className={styles.trackingText}>
                  {formatTime(postWatchCount?.avgWatchTime || '')}
                </Typography>
                <Typography sx={{ color: 'blue', pt: 1 }}>
                  Avg. Watch
                </Typography>
              </Grid>
              <Grid item textAlign={'center'}>
                <Typography className={styles.trackingText}>
                  {formatNumber(postWatchCount?.numberOfShares)}
                </Typography>
                <Typography sx={{ color: 'blue', pt: 1 }}>
                  Number of Shares
                </Typography>
              </Grid>
              <Grid item textAlign={'center'}>
                <Typography className={styles.trackingText}>
                  {formatNumber(postWatchCount?.numberOfViews)}
                </Typography>
                <Typography sx={{ color: 'blue', pt: 1 }}>
                  Number of Views
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {!selectedPost && <Box height={'250px'} />}
      </Box>
    </>
  );
};

const RenderCompanyMediaFeed: FC<{ post: GroupMediaItem; width?: string }> = ({
  post,
  width = '100%',
}) => {
  return (
    <Box
      height={'200px'}
      width={width}
      style={{
        backgroundImage: `url(${
          post?.thumb_link
            ? post?.thumb_link
            : post?.contents?.[0]?.type === 'pdf'
            ? '/static/images/pdf.png'
            : post?.contents?.[0]?.type === 'audio'
            ? '/static/images/mp3.png'
            : post?.contents?.[0]?.type === 'video'
            ? post?.contents?.[0]?.thumbnail ?? ''
            : post?.contents?.[0]?.link ?? ''
        })`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...(!post?.thumb_link &&
        (post?.contents?.[0]?.type === 'pdf' ||
          post?.contents?.[0]?.type === 'audio')
          ? {
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }
          : {}),
        borderRadius: '10px',
        objectFit: 'cover',
        position: 'relative',
      }}
    >
      {post?.contents?.[0]?.type === 'video' && (
        <VideocamIcon
          sx={{
            height: '15%',
            width: '15%',
            color: '#dfe2e4',
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '4rem',
          }}
        />
      )}
    </Box>
  );
};

export default GroupMediaVideoTrackingView;
