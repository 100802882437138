import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Transition } from '../Transition';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AppColors } from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { Content, PostObject } from '../../store/post/api/interface';
import playIcon from '../../assets/play_circle.svg';
import { getPosts } from '../../store/post/api';
import { incrementCurrentPage } from '../../store/post/postSlice';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  onPostMediaFileSelected: (selectedContents: Content[]) => void;
}

interface IInitialState {}

const PostMediaFileSelectionModal: React.FC<IProps> = ({
  toggle,
  onClose,
  onPostMediaFileSelected: onThumbnailTileChanged,
}) => {
  const dispatch = useAppDispatch();
  const observer = useRef<IntersectionObserver>();

  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const posts = useAppSelector((state) => state?.post.data?.data) ?? [];
  const isLoading = useAppSelector((state) => state?.post?.isLoading);
  const hasMore = useAppSelector((state) => state?.post?.hasMore);
  const currentPage = useAppSelector((state) => state?.post?.currentPage);
  const [postContents, setPostContents] = useState<Content[]>([]);

  const [selectedContents, setSelectedContents] = useState<Content[]>([]);

  useEffect(() => {
    dispatch(incrementCurrentPage(0));
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(
        getPosts({
          user_id: userId,
          page: currentPage,
        })
      );
    }
  }, [currentPage]);

  useEffect(() => {
    setPostContents(getPostMedia(posts));
  }, [posts]);

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(incrementCurrentPage(currentPage + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const formik = useFormik<IInitialState>({
    initialValues: {},
    onSubmit: async (values) => {
      try {
        onThumbnailTileChanged(selectedContents);
        onCancel();
      } catch (error: any) {}
    },
  });

  const onCancel = () => {
    formik.resetForm();
    setSelectedContents([]);
    onClose();
  };

  const getPostMedia = (imageVideoData: PostObject[]): Content[] => {
    const medias: Content[] = [];

    for (let i = 0; i < imageVideoData.length; i++) {
      const mediaPostContent = imageVideoData[i].contents;

      for (
        let mediaIndex = 0;
        mediaIndex < mediaPostContent.length;
        mediaIndex++
      ) {
        const content = mediaPostContent[mediaIndex];

        if (
          content.platform !== 'youtube' &&
          content.platform !== 'vimeo' &&
          content.platform !== 'asset' &&
          (content.type === 'image' || content.type === 'video')
        ) {
          medias.push(content);
        }
      }
    }

    return medias;
  };

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        formik.resetForm();
      }}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          // background: 'none',
          // boxShadow: 'none',
          // p: '2%',
        },
      }}
      sx={{
        zIndex: '5000',
      }}
    >
      <DialogTitle>Media Files</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <DialogContent
            sx={{
              // backgroundColor: AppColors.primaryColor,
              position: 'relative',
              minHeight: '50vh',
              borderRadius: '20px',
            }}
          >
            <Grid container spacing={1}>
              {postContents.map((content, index) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  key={index}
                  ref={
                    index + 1 === postContents?.length
                      ? lastElementRef
                      : undefined
                  }
                >
                  <Card
                    onClick={() => {
                      if (selectedContents.includes(content)) {
                        setSelectedContents(
                          selectedContents.filter((e) => e !== content)
                        );
                      } else {
                        const newList = [...selectedContents];
                        newList.push(content);
                        setSelectedContents(newList);
                      }
                    }}
                    style={{
                      backgroundImage: `url(${
                        content?.thumbnail
                          ? content?.thumbnail ?? ''
                          : content?.link ?? ''
                      })`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                      borderRadius: '10px',
                      objectFit: 'cover',
                      minHeight: '130px',
                    }}
                  >
                    {content?.type === 'video' && (
                      <Box
                        component={'img'}
                        src={playIcon}
                        sx={{
                          height: '30%',
                          width: '30%',
                          color: '#dfe2e4',
                          position: 'absolute',
                          bottom: '0',
                          left: '5px',
                          fontSize: '4rem',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                      }}
                    >
                      {selectedContents.includes(content) ? (
                        <RadioButtonCheckedIcon color='primary' />
                      ) : (
                        <RadioButtonUncheckedIcon color='primary' />
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'background.paper',
              zIndex: 1,
            }}
          >
            <Button
              type='button'
              variant={'contained'}
              sx={{
                textTransform: 'unset',
                backgroundColor: 'white',
                color: AppColors.primaryColor,
                ':hover': {
                  color: AppColors.whiteColor,
                },
              }}
              onClick={onCancel}
            >
              <Typography>Cancel</Typography>
            </Button>
            <Button
              type='submit'
              variant='contained'
              sx={{
                textTransform: 'unset',
                backgroundColor: 'white',
                color: AppColors.primaryColor,
                ':hover': {
                  color: AppColors.whiteColor,
                },
              }}
            >
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </FormikProvider>
      </form>
    </Dialog>
  );
};

export default PostMediaFileSelectionModal;
