import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Compressor from 'compressorjs';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/Reports.module.css';
import Page from '../../components/Page';
import { AppDispatch, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { getProfile } from '../../store/auth/api';
import useToggle from '../../hooks/useToggle';
import { isNotEmpty } from '../../utils/valid';
import { AppColors, config } from '../../utils/utils';
import { useDropzone } from 'react-dropzone';
import { AffiliateGroup } from '../../store/AffiliateGroup/api/interface';
import { checkIsProUser } from '../../utils/checkPro';
import { getItem, setItem } from '../../utils/localStorage';
import { inputTextFieldStyle, showErrorMessage } from '../../utils/utilMethods';
import { updateUserProfile } from '../../store/auth/authSlice';
import TeamSetupSchema from '../../validation/teamSetupSchema';
import { LoadingButton } from '@mui/lab';
import {
  createEditAffiliateGroup,
  findAffiliateGroup,
} from '../../store/AffiliateGroup/api';
import { groupUpdated } from '../../store/AffiliateGroup/AffiliateGroupSlice';

const TeamSetup = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);
  const isProUser = checkIsProUser(user);

  const {
    getRootProps: profilePictureGetRootProps,
    getInputProps: profilePictureGetInputProps,
    open: profilePictureOpenDropZone,
    acceptedFiles: profilePictureAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  const [groupData, setGroupData] = useState<AffiliateGroup | undefined>(
    undefined
  );

  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );

  const [metaTag, setMetaTag] = useState('');
  const [metaTagList, setMetaTagList] = useState<string[]>([]);

  const handleMetaTagKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (isNotEmpty(metaTag.trim()) && !metaTagList.includes(metaTag.trim())) {
        const _array = [...metaTagList];
        _array.push(metaTag.trim());
        setMetaTagList(_array);
      }

      setMetaTag('');
    }
  };

  const { toggle, onOpen, onClose } = useToggle();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      lp_link: '',
      lp_title: '',
      lp_target_page: '',
      lp_subtitle: '',
      lp_subtitle_postfix: '',
      shareknocard_prefix: '',
      shareknocard_postfix: '',
      sharemedia_prefix: '',
      sharemedia_postfix: '',
      sharesocial_prefix: '',
      sharesocial_postfix: '',
      sharenewprospect_prefix: '',
      sharenewprospect_postfix: '',
    },
    validationSchema: TeamSetupSchema,
    onSubmit: async () => {
      const response = await dispatch(
        createEditAffiliateGroup({
          id: groupData?.id,
          name: values.name,
          meta_tags: metaTagList.join(','),
          vbcBackground: profilePicture,
          lp_link: values.lp_link,
          lp_target_page: values.lp_target_page,
          lp_title: values.lp_title,
          lp_subtitle: values.lp_subtitle,
          lp_subtitle_postfix: values.lp_subtitle_postfix,
          sharemedia_prefix: values.sharemedia_prefix,
          sharemedia_postfix: values.sharemedia_postfix,
          sharesocial_prefix: values.sharesocial_prefix,
          sharesocial_postfix: values.sharesocial_postfix,
          shareknocard_prefix: values.shareknocard_prefix,
          shareknocard_postfix: values.shareknocard_postfix,
          sharenewprospect_prefix: values.sharenewprospect_prefix,
          sharenewprospect_postfix: values.sharenewprospect_postfix,
        })
      ).unwrap();
      if (response.data && response.data.status.code === 'success') {
        dispatch(groupUpdated(response.data.group));
        setProfilePicture(undefined);
      } else if (response?.data?.status?.description) {
        showErrorMessage(response.data.status.description);
      } else {
        showErrorMessage('Team creation failed.');
      }
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
  } = formik;

  const inputStyle = inputTextFieldStyle();

  const reloadUserProfile = () => {
    dispatch(
      getProfile({
        user_id: user?.id ?? 0,
        viewer_id: user?.id ?? 0,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.data && response.data.status === 'success') {
          dispatch(
            updateUserProfile({
              user: response.data?.data?.user,
            })
          );
        }
      });
  };

  useEffect(() => {
    reloadUserProfile();

    if (getItem(config.USER_PROFILE_PAGE_KEY) === true) {
      setItem(config.USER_PROFILE_PAGE_KEY, false);
    }
  }, []);

  const { data: affiliateGroupList, isLoading: isAffiliateGroupLoading } =
    useAppSelector((state) => state?.affiliateGroup);

  useEffect(() => {
    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }
  }, []);

  useEffect(() => {
    if (affiliateGroupList.length > 0) {
      affiliateGroupList.forEach((item) => {
        if (item.id === user?.affiliate_group_id) {
          // `${item.owner_name}` === user?.username &&
          setGroupData(item);
        }
      });
    }
  }, [affiliateGroupList, user?.username]);

  useEffect(() => {
    if (groupData) {
      values.name = groupData?.name ?? '';
      values.lp_link = groupData?.lp_link ?? '';
      values.lp_title = groupData?.lp_title ?? '';
      values.lp_target_page = groupData?.lp_target_page ?? '';
      values.lp_subtitle = groupData?.lp_subtitle ?? '';
      values.lp_subtitle_postfix = groupData?.lp_subtitle_postfix ?? '';
      values.shareknocard_prefix = groupData?.shareknocard_prefix ?? '';
      values.shareknocard_postfix = groupData?.shareknocard_postfix ?? '';
      values.sharemedia_prefix = groupData?.sharemedia_prefix ?? '';
      values.sharemedia_postfix = groupData?.sharemedia_postfix ?? '';
      values.sharesocial_prefix = groupData?.sharesocial_prefix ?? '';
      values.sharesocial_postfix = groupData?.sharesocial_postfix ?? '';
      values.sharenewprospect_prefix = groupData?.sharenewprospect_prefix ?? '';
      values.sharenewprospect_postfix =
        groupData?.sharenewprospect_postfix ?? '';

      if (isNotEmpty(groupData?.meta_tags)) {
        setMetaTagList(groupData!.meta_tags!.split(','));
      }
    }
  }, [groupData]);

  useEffect(() => {
    if (isNotEmpty(profilePictureAcceptedFiles)) {
      const profilePicture = profilePictureAcceptedFiles[0];
      new Compressor(profilePicture, {
        quality: 0.6,
        success(compressedFile) {
          setProfilePicture(compressedFile as File);
        },
        error(error) {
          setProfilePicture(profilePicture);
        },
      });
    }
  }, [profilePictureAcceptedFiles]);

  return (
    <>
      <Page title='Team Setup | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <FormikProvider value={formik}>
            <Form
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
              onReset={() => resetForm()}
            >
              <Grid container direction={'row'} mt={4}>
                <Grid item xs={12} md={4.5} sx={{ p: 2 }}>
                  <Box className={styles.teamSetupCardBackground}>
                    <Box
                      component='img'
                      className={styles.teamSetupCardImage}
                      src='/static/images/ic_group.png'
                      height='35px'
                    />
                    <Typography
                      className={styles.teamSetupCardTitle}
                      sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}
                    >
                      {groupData ? `Edit` : `Create`} Your Team
                    </Typography>
                    <Typography className={styles.teamSetupCardSubtitle}>
                      New user will choose your team from a dropdown menu. Name
                      cannot be changed.
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={groupData ? true : false}
                      autoComplete='name'
                      placeholder='Add team name'
                      className={styles.teamSetupInput}
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AddCircleIcon
                              sx={{ color: AppColors.greenColor }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <Typography className={styles.teamSetupCardSubtitle}>
                      Add up to 10 meta tags. These keywords will be duplicated
                      for your entire team.
                    </Typography>
                    {!(
                      (isProUser && metaTagList.length >= 10) ||
                      (!isProUser && metaTagList.length >= 3)
                    ) && (
                      <TextField
                        fullWidth
                        value={metaTag}
                        onChange={(e) => setMetaTag(e.target.value)}
                        placeholder='Add meta tag'
                        multiline={true}
                        onKeyUp={handleMetaTagKeyDown}
                        className={styles.teamSetupInput}
                        InputProps={{
                          sx: inputStyle,
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <AddCircleIcon
                                sx={{ color: AppColors.greenColor }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <Box>
                      {metaTagList.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          onDelete={() => {
                            const _array = [...metaTagList];
                            setMetaTagList(
                              _array.filter((tag) => tag !== item)
                            );
                          }}
                          sx={{
                            m: 1,
                            borderRadius: '4px',
                            backgroundColor: AppColors.primaryColor,
                            opacity: 0.8,
                            color: AppColors.whiteColor,
                            '& .MuiChip-deleteIcon': {
                              color: AppColors.whiteColor,
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>

                  <Box className={styles.teamSetupCardBackground} mt={5}>
                    <Box
                      component='img'
                      className={styles.teamSetupCardImage}
                      src='/static/images/ic_landing_page.png'
                      height='50px !important'
                    />
                    <Typography
                      className={styles.teamSetupCardTitle}
                      sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}
                    >
                      Optional Landing Page
                    </Typography>
                    <Typography className={styles.teamSetupCardSubtitle}>
                      KnoCard offers an optional customizable landing page that
                      can be replicated across your team. Users who activate
                      this add-on feature will receive the necessary information
                      to complete the following fields.
                    </Typography>
                    <TextField
                      fullWidth
                      autoComplete='lp_link'
                      placeholder='Add landing page link'
                      className={styles.teamSetupInput}
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AddCircleIcon
                              sx={{ color: AppColors.greenColor }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('lp_link')}
                      error={Boolean(touched.lp_link && errors.lp_link)}
                      helperText={touched.lp_link && errors.lp_link}
                    />
                    <TextField
                      fullWidth
                      autoComplete='lp_title'
                      placeholder='Add landing page title'
                      className={styles.teamSetupInput}
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AddCircleIcon
                              sx={{ color: AppColors.greenColor }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('lp_title')}
                      error={Boolean(touched.lp_title && errors.lp_title)}
                      helperText={touched.lp_title && errors.lp_title}
                    />
                    <TextField
                      fullWidth
                      autoComplete='lp_target_page'
                      placeholder='Add landing page target link'
                      className={styles.teamSetupInput}
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AddCircleIcon
                              sx={{ color: AppColors.greenColor }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('lp_target_page')}
                      error={Boolean(
                        touched.lp_target_page && errors.lp_target_page
                      )}
                      helperText={
                        touched.lp_target_page && errors.lp_target_page
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={7.5} direction={'column'} sx={{ p: 2 }}>
                  {!(profilePicture || groupData?.background_link) && (
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: '100%', md: '70%' },
                        position: 'relative',
                        borderRadius: '20px' /* Rounded corners */,
                        backgroundColor: '#072E54',
                        padding: '30px' /* Padding inside the div */,
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '20px' /* Rounded corners */,
                          padding: '30px' /* Padding inside the div */,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23e5c078' stroke-width='6' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        }}
                      >
                        <Box textAlign={'center'}>
                          <Box
                            component='img'
                            src='/static/images/ic_upload.png'
                            mb={5}
                            onClick={() => {
                              profilePictureOpenDropZone();
                            }}
                            sx={{ cursor: 'pointer' }}
                          />
                          <Typography variant='h2' color={'white'}>
                            Add or Edit <br /> Your Banner Here
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {(profilePicture || groupData?.background_link) && (
                    <Stack
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Card
                        sx={{
                          width: '100%',
                          height: { xs: '100%', md: '70%' },
                          position: 'relative',
                          borderRadius: '20px',
                        }}
                      >
                        <Box
                          component={'img'}
                          // className='cover-img'
                          src={
                            profilePicture
                              ? URL.createObjectURL(profilePicture!)
                              : isNotEmpty(groupData?.background_link)
                              ? groupData?.background_link
                              : '/static/images/group_bg/knocard.jpg'
                          }
                          sx={{
                            borderRadius: '20px',
                            objectFit: 'cover',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: 'inherit',
                          }}
                        />

                        <Button
                          variant={'contained'}
                          sx={{
                            position: 'absolute',
                            bottom: 20,
                            right: 20,
                            textTransform: 'unset',
                            borderRadius: '10px',
                          }}
                          startIcon={
                            <Box
                              component={'img'}
                              src={'/static/images/ic_pencil.png'}
                              sx={{ height: '20px' }}
                            />
                          }
                          onClick={() => {
                            profilePictureOpenDropZone();
                          }}
                        >
                          <Typography>Edit Banner</Typography>
                        </Button>
                      </Card>
                    </Stack>
                  )}
                  <Box
                    {...profilePictureGetRootProps({
                      className: 'dropzone',
                    })}
                  >
                    <input {...profilePictureGetInputProps()} />
                  </Box>
                </Grid>

                <Box className={styles.teamSetupCardBackground} mt={5}>
                  <Box
                    component='img'
                    className={styles.teamSetupCardImage}
                    src='/static/images/ic_chat.png'
                    height='50px !important'
                  />
                  <Typography
                    className={styles.teamSetupCardTitle}
                    sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}
                  >
                    Customize Messages
                  </Typography>
                  <Typography className={styles.teamSetupCardSubtitle}>
                    Create a custom text message that will be pre-populated when
                    your team members share with their prospects. To ensure
                    optimal deliverability, include both a prefix and postfix,
                    which will appear before and after the shared KnoCard link.
                  </Typography>

                  <Typography className={styles.teamSetupCardSectionTitle}>
                    Shared Web App Link
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete='shareknocard_prefix'
                    placeholder='Add prefix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('shareknocard_prefix')}
                    error={Boolean(
                      touched.shareknocard_prefix && errors.shareknocard_prefix
                    )}
                    helperText={
                      touched.shareknocard_prefix && errors.shareknocard_prefix
                    }
                  />
                  <TextField
                    fullWidth
                    autoComplete='shareknocard_postfix'
                    placeholder='Add postfix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('shareknocard_postfix')}
                    error={Boolean(
                      touched.shareknocard_postfix &&
                        errors.shareknocard_postfix
                    )}
                    helperText={
                      touched.shareknocard_postfix &&
                      errors.shareknocard_postfix
                    }
                  />

                  <Typography className={styles.teamSetupCardSectionTitle}>
                    Shared Media Files
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete='sharemedia_prefix'
                    placeholder='Add prefix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharemedia_prefix')}
                    error={Boolean(
                      touched.sharemedia_prefix && errors.sharemedia_prefix
                    )}
                    helperText={
                      touched.sharemedia_prefix && errors.sharemedia_prefix
                    }
                  />
                  <TextField
                    fullWidth
                    autoComplete='sharemedia_postfix'
                    placeholder='Add postfix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharemedia_postfix')}
                    error={Boolean(
                      touched.sharemedia_postfix && errors.sharemedia_postfix
                    )}
                    helperText={
                      touched.sharemedia_postfix && errors.sharemedia_postfix
                    }
                  />

                  <Typography className={styles.teamSetupCardSectionTitle}>
                    Shared Social Post
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete='sharesocial_prefix'
                    placeholder='Add prefix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharesocial_prefix')}
                    error={Boolean(
                      touched.sharesocial_prefix && errors.sharesocial_prefix
                    )}
                    helperText={
                      touched.sharesocial_prefix && errors.sharesocial_prefix
                    }
                  />
                  <TextField
                    fullWidth
                    autoComplete='sharesocial_postfix'
                    placeholder='Add postfix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharesocial_postfix')}
                    error={Boolean(
                      touched.sharesocial_postfix && errors.sharesocial_postfix
                    )}
                    helperText={
                      touched.sharesocial_postfix && errors.sharesocial_postfix
                    }
                  />

                  <Typography className={styles.teamSetupCardSectionTitle}>
                    Shared with New Prospect
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete='sharenewprospect_prefix'
                    placeholder='Add prefix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharenewprospect_prefix')}
                    error={Boolean(
                      touched.sharenewprospect_prefix &&
                        errors.sharenewprospect_prefix
                    )}
                    helperText={
                      touched.sharenewprospect_prefix &&
                      errors.sharenewprospect_prefix
                    }
                  />
                  <TextField
                    fullWidth
                    autoComplete='sharenewprospect_postfix'
                    placeholder='Add postfix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('sharenewprospect_postfix')}
                    error={Boolean(
                      touched.sharenewprospect_postfix &&
                        errors.sharenewprospect_postfix
                    )}
                    helperText={
                      touched.sharenewprospect_postfix &&
                      errors.sharenewprospect_postfix
                    }
                  />

                  <Typography className={styles.teamSetupCardSectionTitle}>
                    Shared Group Landing Page
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete='lp_subtitle'
                    placeholder='Add prefix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('lp_subtitle')}
                    error={Boolean(touched.lp_subtitle && errors.lp_subtitle)}
                    helperText={touched.lp_subtitle && errors.lp_subtitle}
                  />
                  <TextField
                    fullWidth
                    autoComplete='lp_subtitle_postfix'
                    placeholder='Add postfix message'
                    className={styles.teamSetupInput}
                    InputProps={{
                      sx: inputStyle,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AddCircleIcon sx={{ color: AppColors.greenColor }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('lp_subtitle_postfix')}
                    error={Boolean(
                      touched.lp_subtitle_postfix && errors.lp_subtitle_postfix
                    )}
                    helperText={
                      touched.lp_subtitle_postfix && errors.lp_subtitle_postfix
                    }
                  />
                </Box>
                <Grid
                  xs={12}
                  justifyContent={'center'}
                  sx={{ textAlign: 'center', mb: 6, mt: 2 }}
                >
                  <LoadingButton
                    //   fullWidth

                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                    sx={{
                      pl: 6,
                      pr: 6,
                      pt: 1,
                      pb: 1,
                      textTransform: 'unset',
                      fontSize: '1.0rem',
                    }}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Container>
      </Page>
    </>
  );
};
export default TeamSetup;
