import { STATUS_TYPE } from '../../../enum';
import { Company, UserInfo } from '../../userInfo/api/interface';

export interface CompanyFeedRequest {
  page: number;
  companyId?: number;
  user_id?: number;
}


export interface CreateUpdateCompanyFeedRequest {
  userId: number;
  feedId?: number;
  title: string;
  companyId?: number;
  groupId?: number;
  description: string;
  mediaData: ContentFile[];
  deleteContents: number[];
  isIncludedGroupShare?: number;
}


export interface ReportSocialFeedResponse {
  status?: {
    code?: string;
    description?: string;
  },
  report?: {
    user_id?: number;
    report_type_id?: number;
    reportable_id?: number;
    reportable_type?: string;
    sort_marker?: string;
    message?: string;
    updated_at?: string;
    created_at?: string;
    id?: number;
  }
}


export interface ContentFile {
  file?: File;
  type: string;
  thumbnail?: File;
  title: string;
  description: string;
  link?: string;
  thumbnail_link?: string;
  platform: string;
  contents?: CompanyFeedContents;
}


export interface CompanyFeedResponse {
  status: {
    code: string;
    description: string;
  };
  data: {
    current_page: number;
    per_page: number;
    last_page: number;
    total: number;
    data: CompanyFeed[];
  }
}

export interface CompanyFeed {
  id: number;
  company_id: number;
  group_social_post_id?: number;
  title: string;
  image: null | string;
  description: string;
  created_at: string;
  updated_at: string;
  video: null;
  shares_count: number;
  reactions_count: number;
  comments_count: number;
  views_count: number;
  deep_link: null | string;
  contents: CompanyFeedContents[];
  company: Company;
  reaction?: CompanyFeedReaction;
  comments?: CompanyFeedComments[];
  status?: number;
}

export interface CompanyFeedComments {
  comment: string;
  company_feed_id: number;
  created_at: string;
  filename?: string;
  id: number;
  link?: string;
  migrate_s3: number;
  status: number;
  updated_at: string;
  user?: UserInfo
  user_id: number;

}

export interface CompanyFeedReaction {
  id: number;
  company_feed_id: number;
  created_at: string;
  reaction_id: number;
  updated_at: string;
  user_id: number;
}

export interface CompanyFeedContents {
  id: number;
  company_feed_id: number;
  user_id: number;
  type: string;
  link: string;
  thumbnail: null | string;
  status: number,
  created_at: string;
  updated_at: string;
  filename: null | string;
  thumb_filename: null | string;
  title: string;
  platform: string;
  position: number,
  description: string;
}

export interface PinCompanyFeedResponse {
  status: {
    code: string;
    description: string;
  };
  data: {
    current_page: number;
    per_page: number;
    last_page: number;
    total: number;
    data: CompanyFeed[];
  };
}

interface InitialState {
  data: CompanyFeed[];
  pinFeeds: CompanyFeed[];
  shareSocialPostMessage: string | undefined;
  shareSocialPost: CompanyFeed | undefined;
  status: STATUS_TYPE;
  page: number;
  isLoadMore: boolean;
  pinFeedPage: number;
  pinFeedIsLoadMore: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  pinFeeds: [],
  shareSocialPostMessage: undefined,
  shareSocialPost: undefined,
  status: 'idle',
  isError: false,
  isLoading: false,
  page: 1,
  isLoadMore: false,
  pinFeedPage: 1,
  pinFeedIsLoadMore: false,
  isSuccess: false,
  errorMessage: '',
};
