import { Box, Stack, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { AppColors } from '../utils/utils';

interface IProps {
  link: string;
}

const GoogleReviewsButton: React.FC<IProps> = ({ link }) => {
  const handleGoogleReviews = () => {
    window.open(`${link}`) || window.location.replace(`${link}`);
  };

  return (
    <Stack display={'table'}>
      <Box
        display='flex'
        alignItems='center'
        gap={1}
        borderRadius={2}
        border={1}
        borderColor={AppColors.grayOneColor}
        paddingY={0.5}
        paddingX={1}
        // boxShadow={1}
        bgcolor='white'
        onClick={handleGoogleReviews}
        sx={{cursor: 'pointer'}}
      >
        {/* Google Logo */}
        <Box
          component={'img'}
          src='/static/images/google_logo.png'
          alt='Google Logo'
          width={'30px'}
          height={'30px'}
        />

        <Box display={'flex'} flexDirection={'column'} alignItems='center'>
          {/* Reviews Text */}
          <Typography
            component={'p'}
            variant='h6'
            fontWeight={500}
            color={AppColors.grayThreeColor}
            lineHeight={1}
          >
            Reviews
          </Typography>

          {/* Star Ratings */}
          <Box display='flex' alignItems='center'>
            {[...Array(5)].map((_, index) => (
              <StarIcon key={index} sx={{ color: '#FFD700', margin: '-2px' }} />
            ))}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default GoogleReviewsButton;
