import React, { useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { MediaType } from '../../../enum';
import { inputTextFieldStyle } from '../../../utils/utilMethods';
import { AppColors } from '../../../utils/utils';
import SendIcon from '../../../assets/chat/send.svg';
import AddCircle from '@mui/icons-material/AddCircle';

interface IProps {
  message: string;
  setMessage: (message: string) => void;
  sendMessage: () => void;
  selectMedia: (type: string) => void;
  isMediaSelectionEnable: boolean;
}

const InputGroupMessage: React.FC<IProps> = ({
  message,
  setMessage,
  sendMessage,
  selectMedia,
  isMediaSelectionEnable,
}) => {
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <Grid container sx={{ padding: 0, m: 0.5 }}>
      <Box
        component={'form'}
        sx={{
          width: '100%',
          margin: '1rem',
        }}
        onSubmit={handleSubmit}
      >
        <Stack flexDirection={'row'} display={'flex'} justifyContent={'center'}>
          <Box
            justifyContent={'center'}
            alignContent={'center'}
            sx={{ flexGrow: 1 }}
          >
            <TextField
              size='small'
              fullWidth
              variant='outlined'
              placeholder='Add your message'
              value={message}
              onChange={handleMessageChange}
              InputProps={{
                sx: {
                  ...inputTextFieldStyle(),
                  backgroundColor: AppColors.whiteColor,
                  borderRadius: '12px',
                  p: 1,
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <Stack flexDirection={'row'}>
                      <IconButton
                        sx={{ color: AppColors.grayTwoColor }}
                        // onClick={() => selectMedia(MediaType.image)}
                        disabled={!isMediaSelectionEnable}
                      >
                        <AddCircle sx={{ color: '#4CAF50' }} />
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                ),
                // endAdornment: (
                //   <InputAdornment position='end'>
                //     <Stack flexDirection={'row'}>
                //       <IconButton
                //         sx={{ color: AppColors.grayTwoColor }}
                //         onClick={() => selectMedia(MediaType.image)}
                //         disabled={!isMediaSelectionEnable}
                //       >
                //         <ImageIcon />
                //       </IconButton>
                //       <IconButton
                //         sx={{ color: AppColors.grayTwoColor }}
                //         onClick={() => selectMedia(MediaType.video)}
                //         disabled={!isMediaSelectionEnable}
                //       >
                //         <VideoLibraryIcon />
                //       </IconButton>
                //     </Stack>
                //   </InputAdornment>
                // ),
              }}
            />
          </Box>
          <IconButton
            color='primary'
            onClick={sendMessage}
            disabled={!message.trim()}
          >
            <Box component={'img'} src={SendIcon} />
          </IconButton>
        </Stack>
      </Box>
    </Grid>
  );
};

export default InputGroupMessage;
