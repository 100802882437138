import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../../styles/Reports.module.css';
import Page from '../../components/Page';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Compressor from 'compressorjs';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, useAppSelector } from '../../store';
import { AppColors, config } from '../../utils/utils';
import { checkIsProUser } from '../../utils/checkPro';
import { GroupReportCountItem } from '../../components/Reports/GroupReportCountItem';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import InputGroupMessage from '../../components/Chat/ChatSection/InputGroupMessage';
import {
  ChatMessage,
  ChatMessageMedia,
  GroupChat,
  GroupMessageUnrealCount,
  SelectedChatMessageMedia,
} from '../../store/chat/api/interface';
import { MediaType, NotificationType } from '../../enum';
import { formatNumber, showErrorMessage } from '../../utils/utilMethods';
import { acceptImageFileTypes, acceptVideoFileTypes } from '../../constant';
import SearchTeamMemberView from '../../components/SearchTeamMemberView';
import {
  getAllReportTotal,
  getGroupUserAndGoalCount,
} from '../../store/corporateReporting/api';
import { getDaysFromFilter } from '../../utils/daysConvertor';
import { uploadMediaCloudFlare } from '../../store/mediaUpload/api';
import {
  getSingleGroup,
  sendGroupChatMessage,
  updateGroupUnreadMessageCount,
} from '../../client/FireStoreChat';
import { setGroupChat } from '../../store/chat/chatSlice';
import { sendChatMessageNotification } from '../../store/chat/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import _ from 'lodash';
import GroupMediaVideoTrackingView from './GroupMediaVideoTrackingView';
import GroupSocialVideoTrackingView from './GroupSocialVideoTrackingView';
import GroupLandingPageTrackingView from './GroupLandingPageTrackingView';

interface ActivityPercentage {
  index?: number;
  title: string;
  value: number;
  color?: string;
}

const GroupReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);
  const corporateReporting = useAppSelector(
    (state) => state?.corporateReporting
  );
  const { allReportSummary } = useAppSelector(
    (state) => state?.corporateReporting
  );

  const { groupChat } = useAppSelector((state) => state?.chat);

  const [activityPercentages, setActivityPercentages] = useState<
    ActivityPercentage[] | undefined
  >(undefined);

  const isProUser = checkIsProUser(user);

  const mainContentRef = useRef<null | HTMLDivElement>(null);
  const reportDetailRef = useRef<null | HTMLDivElement>(null);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { data: affiliateGroupList, isLoading: isAffiliateGroupLoading } =
    useAppSelector((state) => state?.affiliateGroup);

  const [message, setMessage] = useState<string>('');
  const [sendingMessage, setSendingMessage] = useState<
    | { message: string; chatMessageMedia: SelectedChatMessageMedia[] }
    | undefined
  >(undefined);
  const [messageMedia, setMessageMedia] = useState<SelectedChatMessageMedia[]>(
    []
  );
  const [selectMediaType, setSelectMediaType] = useState<string>('');
  const [isVideoLoading, setVideoLoading] = useState<boolean>(false);

  const RADIAN = Math.PI / 180;

  const [selectedTimeFilter, setSelectedTimeFilter] = useState('Month');
  const timeFilterList = ['Today', 'Week', 'Month', 'All Time'];

  const fileUploader = useRef<any>(null);

  const [activeBtn, setActiveBtn] = useState<string | undefined>(undefined);

  const scrollToTop = () => {
    mainContentRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToReportDetail = () => {
    reportDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (
      user?.affiliate_group == null ||
      user?.username !== user?.affiliate_group?.owner_name
    ) {
      navigate(`/dashboard`);
    } else {
      loadCorporateGroupUserAndGoalCount();
    }
  }, []);

  useEffect(() => {
    loadCorporateGroupUserAndGoalCount();
    loadReportCount();
  }, [selectedTimeFilter]);

  useEffect(() => {
    loadGroupChatData();
    calculatePercentages();
  }, [allReportSummary]);

  const loadCorporateGroupUserAndGoalCount = () => {
    dispatch(
      getGroupUserAndGoalCount({
        days: getDaysFromFilter(selectedTimeFilter),
      })
    );
  };

  const loadReportCount = () => {
    dispatch(
      getAllReportTotal({
        userId: user?.id ?? 0,
        days: getDaysFromFilter(selectedTimeFilter),
      })
    ).unwrap();
  };

  const loadGroupChatData = () => {
    if (user?.affiliate_group?.chat_group_id) {
      getSingleGroup(user?.affiliate_group?.chat_group_id, (_groupChatData) => {
        dispatch(setGroupChat(_groupChatData));
      });
    }
  };

  const handleOnCommandCenterClicked = () => {
    navigate('/dashboard/leader-corner');
  };

  const handleChangeTimeFilter = (event: SelectChangeEvent) => {
    setSelectedTimeFilter(event.target.value as string);
  };

  const openMediaSelector = () => {
    fileUploader?.current?.click();
  };

  useEffect(() => {
    if (
      selectMediaType == MediaType.image ||
      selectMediaType == MediaType.video
    ) {
      openMediaSelector();
    }
  }, [selectMediaType]);

  function onSelectMediaTapped(mediaType: string) {
    if (selectMediaType == mediaType) {
      openMediaSelector();
    } else {
      setSelectMediaType(mediaType);
    }
  }

  const handleRemoveSelectedMedia = (
    selectedChatMessageMedia: SelectedChatMessageMedia
  ) => {
    setMessageMedia(
      messageMedia.filter((item) => item != selectedChatMessageMedia)
    );
  };

  const activityCards = [
    {
      _id: 1,
      title: 'Personal Shares',
      code: 'webAppShare',
      showButtons: false,
      color: '#2F5F98',
    },
    {
      _id: 2,
      title: 'Media Shares',
      code: 'mediaShare',
      showButtons: false,
      color: '#2D8AB8',
    },
    {
      _id: 3,
      title: 'Landing Page Shares',
      code: 'group_landing_page',
      showButtons: false,
      color: '#895073',
    },
    {
      _id: 4,
      title: 'Social Posts Shares',
      code: 'shareSocialPosts',
      showButtons: false,
      color: '#5F3868',
    },
    {
      _id: 5,
      title: 'New Prospects',
      code: 'lead',
      showButtons: false,
      color: '#30356D',
    },
    {
      _id: 6,
      title: 'Page Views',
      code: 'knocard',
      showButtons: false,
      color: '#6CE4E9',
    },
    {
      _id: 7,
      title: 'Video Views',
      code: 'mediaVideoViewCount',
      showButtons: true,
      color: '#40B8D7',
    },
    {
      _id: 8,
      title: 'Landing Pages Views',
      code: 'groupLandingPageView',
      showButtons: true,
      color: '#5D4F9E',
    },
    {
      _id: 9,
      title: 'Social Post Views',
      code: 'viewSocialPosts',
      showButtons: true,
      color: '#F29C11',
    },
  ];

  const getReportSummaryCount = (
    title: string,
    index: number
  ): { count: number; previousCount: number } => {
    if (title === 'New Prospects') {
      return {
        count: allReportSummary?.contact || 0,
        previousCount: allReportSummary?.previousContact || 0,
      };
    }

    if (title === 'Page Views') {
      return {
        count: allReportSummary?.views || 0,
        previousCount: allReportSummary?.previousViews || 0,
      };
    }

    if (title === 'Video Views') {
      return {
        count: allReportSummary?.viewVideoMediaPost || 0,
        previousCount: allReportSummary?.previousViewVideoMediaPost || 0,
      };
    }

    if (title === 'Media Shares') {
      return {
        count: allReportSummary?.shareMedia || 0,
        previousCount: allReportSummary?.previousShareMedia || 0,
      };
    }

    if (title === 'Personal Shares') {
      return {
        count: allReportSummary?.webAppShare || 0,
        previousCount: allReportSummary?.previousWebAppShare || 0,
      };
    }

    if (title === 'Social Posts Shares') {
      return {
        count: allReportSummary?.shareSocialPosts || 0,
        previousCount: allReportSummary?.previousShareSocialPosts || 0,
      };
    }

    if (title === 'Referrals') {
      return {
        count: allReportSummary?.referrals || 0,
        previousCount: allReportSummary?.previousReferrals || 0,
      };
    }

    if (title === 'Landing Page Shares') {
      return {
        count: allReportSummary?.groupLandingPageShare || 0,
        previousCount: allReportSummary?.previousGroupLandingPageShare || 0,
      };
    }

    if (title === 'Landing Pages Views') {
      return {
        count: allReportSummary?.groupLandingPageVideoView || 0,
        previousCount: allReportSummary?.previousGroupLandingPageVideoView || 0,
      };
    }

    if (title === 'Social Post Views') {
      return {
        count: allReportSummary?.communityFeedVideoView || 0,
        previousCount: allReportSummary?.previousCommunityFeedVideoView || 0,
      };
    }

    return { count: 0, previousCount: 0 };
  };

  // Function to calculate percentages
  const calculatePercentages = () => {
    const activityValue: ActivityPercentage[] = [];
    activityCards.map((card) => {
      let reportData = getReportSummaryCount(
        card.title,
        timeFilterList.indexOf(selectedTimeFilter)
      );
      activityValue.push({
        title: card.title,
        value: reportData.count,
      });
    });

    const total = activityValue.reduce((acc: any, val: any) => {
      return acc + parseFloat(val.value || 0);
    }, 0);
    const newPercentages: ActivityPercentage[] = activityValue.map(
      (item: any) => {
        return {
          title: item.title,
          value: total ? (parseFloat(item.value || 0) / total) * 100 : 0,
        };
      }
    );

    setActivityPercentages(newPercentages.filter((item) => item.value > 0));
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        fontSize={'.7rem'}
      >
        {`${activityPercentages![index]?.title} ${activityPercentages![
          index
        ].value.toFixed(1)}%`}
      </text>
    );
  };

  const onFileSelect = async (event: any) => {
    var selectedMediaCount = messageMedia.length;
    Array.from(event.target.files).forEach((file: any, index: number) => {
      const fileType = acceptVideoFileTypes.includes(file.type)
        ? MediaType.video
        : MediaType.image;
      if (selectedMediaCount < 3) {
        if (fileType == MediaType.image) {
          //  if (config.CHAT_IMAGE_MAX_SIZE >= (file.size / (1024 * 1024))) {
          selectedMediaCount += 1;
          new Compressor(file, {
            quality: 0.5,
            success(compressedFile) {
              setMessageMedia([
                ...messageMedia,
                {
                  image: compressedFile as File,
                  type: fileType,
                },
              ]);
            },
            error(error) {
              setMessageMedia([
                ...messageMedia,
                {
                  image: file,
                  type: fileType,
                },
              ]);
            },
          });
          /*} else {
                          showErrorMessage(`Image size exceeds the maximum limit is ${config.CHAT_IMAGE_MAX_SIZE}MB`);
                      }*/
        } else if (fileType == MediaType.video) {
          if (config.CHAT_VIDEO_MAX_SIZE >= file.size / (1024 * 1024)) {
            setVideoLoading(true);
            selectedMediaCount += 1;
            const videoURL = URL.createObjectURL(file);

            // Create a video element to capture a frame for the thumbnail
            const videoElement = document.createElement('video');
            videoElement.src = videoURL;

            // Wait for metadata to load, then capture a frame
            videoElement.onloadedmetadata = () => {
              videoElement.currentTime = 5; // Seek to 5 seconds (you can adjust this)
              videoElement.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                const context = canvas.getContext('2d');
                context?.drawImage(
                  videoElement,
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );

                canvas.toBlob(
                  (blob) => {
                    if (blob != null) {
                      const thumbnailBlob: any = new Blob([blob], {
                        type: 'image/jpeg',
                      }); // You can adjust the type as needed
                      thumbnailBlob.lastModifiedDate = new Date();
                      thumbnailBlob.name = `${Date.now()}.jpeg`;

                      setMessageMedia([
                        ...messageMedia,
                        {
                          video: file,
                          thumbnail: thumbnailBlob,
                          type: fileType,
                        },
                      ]);
                    }
                    // Clean up
                    URL.revokeObjectURL(videoURL);
                    videoElement.remove();
                    setVideoLoading(false);
                  },
                  'image/jpeg',
                  0.6
                );
              };
            };
          } else {
            showErrorMessage(
              `Video size exceeds the maximum limit is ${config.CHAT_VIDEO_MAX_SIZE}MB`
            );
          }
        }
      }
    });
  };

  const handleSendMessage = async () => {
    if (groupChat && message.length > 0) {
      let chatMessage = message;
      let messageMediaList = messageMedia;
      setMessage('');
      setMessageMedia([]);
      let chatMessageMedia: ChatMessageMedia[] = [];
      setSendingMessage({
        message: chatMessage,
        chatMessageMedia: messageMediaList,
      });
      for (let i = 0; i < messageMediaList.length; i++) {
        let messageMediaItem = messageMediaList[i];

        var mediaResult = await dispatch(
          uploadMediaCloudFlare({
            resourceType: 'MESSAGE',
            ...(messageMediaItem.type == MediaType.video
              ? { video: messageMediaItem.video }
              : { image: messageMediaItem.image }),
          })
        ).unwrap();
        if (mediaResult.data && mediaResult.data.status === 'success') {
          let tmpMessageMedia: ChatMessageMedia = {
            link: mediaResult.data.data.link,
            type: messageMediaItem.type,
          };
          if (
            messageMediaItem.type == MediaType.video &&
            messageMediaItem.thumbnail != undefined
          ) {
            var mediaResult = await dispatch(
              uploadMediaCloudFlare({
                resourceType: 'MESSAGE',
                thumbnail: messageMediaItem.thumbnail,
              })
            ).unwrap();
            if (mediaResult.data && mediaResult.data.status === 'success') {
              tmpMessageMedia.thumbnail = mediaResult.data.data.link;
            }
          }
          chatMessageMedia.push(tmpMessageMedia);
        }
      }

      sendGroupChatMessage(
        groupChat,
        user?.id ?? 0,
        chatMessage,
        chatMessageMedia
      ).then((result: ChatMessage) => {
        setSendingMessage(undefined);
        sendChatNotificationToGroupMembers(result, groupChat!);
      });
    }
  };

  const sendChatNotificationToGroupMembers = (
    newMessage: ChatMessage,
    groupChat: GroupChat
  ) => {
    // Get user IDs from unreadMessageCount
    const unreadMessageCountUserList = groupChat?.unreadMessageCount?.map(
      (e) => e.userId
    );

    // Create a copy of unreadMessageCount for modification
    let unreadMessageCountList: GroupMessageUnrealCount[] = [
      ...(groupChat.unreadMessageCount ?? []),
    ];

    // If there are users in the group who do not have an unread message count, add them
    if (
      (groupChat.users?.length || 0) > (unreadMessageCountUserList?.length || 0)
    ) {
      groupChat.users?.forEach((userId) => {
        if (!unreadMessageCountUserList?.includes(userId)) {
          unreadMessageCountList.push({ userId, count: 0 });
        }
      });
    }

    // Remove unread message counts for users not in the group
    if (
      (unreadMessageCountUserList?.length || 0) > (groupChat.users?.length || 0)
    ) {
      groupChat.unreadMessageCount?.forEach((element) => {
        if (!groupChat.users?.includes(element.userId || 0)) {
          unreadMessageCountList = unreadMessageCountList.filter(
            (item) => item.userId !== element.userId
          );
        }
      });
    }

    // Update unread message counts, resetting the sender's count to 0
    unreadMessageCountList = unreadMessageCountList.map(
      (unreadMessageCount) => {
        if (unreadMessageCount.userId === newMessage.from) {
          return { ...unreadMessageCount, count: 0 }; // Reset count for sender
        } else {
          return {
            ...unreadMessageCount,
            count: (unreadMessageCount?.count || 0) + 1,
          }; // Increment count for others
        }
      }
    );

    // Remove the sender from the user list
    const userIdList = (groupChat.users || []).filter(
      (userId) => userId !== newMessage.from
    );
    if (userIdList.length > 0) {
      dispatch(
        sendChatMessageNotification({
          message: newMessage.text,
          notification_type: NotificationType.group,
          receiver_id: userIdList,
          sender_id: newMessage.from,
        })
      );
    }

    updateGroupUnreadMessageCount(groupChat?.id || '', unreadMessageCountList);
  };

  const onViewReportClicked = (reportType: string) => {
    setActiveBtn(reportType);
    // scrollToReportDetail();
    switch (reportType) {
      case 'mediaVideoViewCount':
        navigate(`/dashboard/group-media-video-tracking/${selectedTimeFilter}`);
        break;
      case 'groupLandingPageView':
        navigate(
          `/dashboard/group-landing-page-video-tracking/${selectedTimeFilter}`
        );
        break;
      case 'viewSocialPosts':
        navigate(
          `/dashboard/group-social-video-tracking/${selectedTimeFilter}`
        );
        break;
    }
  };

  return (
    <>
      <Page title='Portal | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box
            className={styles.mainContent}
            marginTop={5}
            ref={mainContentRef}
          >
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              display={'flex'}
              justifyContent={'space-between'}
              alignContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography sx={{ fontSize: '2rem', fontWeight: '500' }}>
                Welcome to the KnoCard Portal
              </Typography>
              <Stack
                direction={'row'}
                display={'flex'}
                alignContent={'center'}
                alignItems={'center'}
                spacing={4}
                px={3}
                py={0.5}
                sx={{
                  backgroundImage:
                    'url("/static/images/tutorial_background.svg")',
                  borderRadius: '8px',
                }}
              >
                <Stack
                  alignItems={'center'}
                  onClick={handleOnCommandCenterClicked}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box
                    component={'img'}
                    src='/static/images/command_center_img.png'
                    height={'60px'}
                    pb={1}
                  />
                  <Typography sx={{ fontSize: '0.8rem', color: 'white' }}>
                    Command Center
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box className={styles.mainContentBody}>
              <Grid
                container
                gap={2}
                justifyContent={'space-between'}
                sx={{ mb: 6 }}
              >
                <Grid item md={5.8} sm={5.5} xs={12}>
                  <Grid
                    container
                    gap={1}
                    direction={{ sm: 'row', xs: 'column' }}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Grid item sm={5.8} xs={5.8}>
                      <Stack
                        direction={'row'}
                        display={'flex'}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        textAlign={'center'}
                        spacing={2}
                        sx={{
                          p: { md: 2, xs: 0 },
                          backgroundColor: {
                            md: AppColors.whiteColor,
                            xs: 'unset',
                          },
                          borderRadius: '10px',
                          position: 'relative',
                        }}
                      >
                        <Box
                          component={'img'}
                          src='/static/images/knocard_360.png'
                          sx={{ ml: 2, mt: 2, height: '120px' }}
                        />
                        <Stack
                          display={'flex'}
                          justifyContent={'center'}
                          alignContent={'center'}
                          spacing={2}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: '1.2rem', color: 'black' }}
                            >
                              {formatNumber(
                                corporateReporting
                                  .corporateGroupUserAndGoalCount
                                  ?.total_group_user_count
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '1rem',
                                color: AppColors.primaryColor,
                              }}
                            >
                              Total Members
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontSize: '1.2rem', color: 'black' }}
                            >
                              {formatNumber(
                                corporateReporting
                                  .corporateGroupUserAndGoalCount
                                  ?.new_group_user_count
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '1rem',
                                color: AppColors.primaryColor,
                              }}
                            >
                              New Members
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item sm={5.8} xs={5.8}>
                      <Stack
                        display={'flex'}
                        direction={'row'}
                        justifyContent={'space-around'}
                      >
                        {/* Goals Not Met */}
                        <Box>
                          <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            width='100px'
                            height='100px'
                            borderRadius='50%'
                            bgcolor='#F14450'
                            color='white'
                            fontSize='20px'
                            fontWeight='bold'
                          >
                            <Box
                              component={'img'}
                              src={'/static/images/ic_user.png'}
                              alignSelf={'end'}
                              height={'80%'}
                              sx={{
                                opacity: 0.2,
                              }}
                            />

                            <Typography
                              position={'absolute'}
                              component={'p'}
                              variant={'h5'}
                            >
                              {
                                corporateReporting
                                  .corporateGroupUserAndGoalCount
                                  ?.goal_not_met_count
                              }
                            </Typography>
                          </Box>
                          <Typography
                            component={'p'}
                            variant={'body1'}
                            textAlign={'center'}
                            mt={1}
                          >
                            Goals Not Met
                          </Typography>
                        </Box>

                        {/* Goals Met */}
                        <Box>
                          <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            width='100px'
                            height='100px'
                            borderRadius='50%'
                            bgcolor='#00BF63'
                            color='white'
                            fontSize='20px'
                            fontWeight='bold'
                          >
                            <Box
                              component={'img'}
                              src={'/static/images/ic_user.png'}
                              alignSelf={'end'}
                              height={'80%'}
                              sx={{
                                opacity: 0.2,
                              }}
                            />

                            <Typography
                              position={'absolute'}
                              component={'p'}
                              variant={'h5'}
                            >
                              {
                                corporateReporting
                                  .corporateGroupUserAndGoalCount
                                  ?.goal_met_count
                              }
                            </Typography>
                          </Box>
                          <Typography
                            component={'p'}
                            variant={'body1'}
                            textAlign={'center'}
                            mt={1}
                          >
                            Goals Met
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid container gap={1} ml={{ xs: 0, sm: 0, md: 1 }}>
                      {activityCards?.length &&
                        activityCards.map((card) => {
                          let reportData = getReportSummaryCount(
                            card.title,
                            timeFilterList.indexOf(selectedTimeFilter)
                          );
                          return (
                            <Grid item sm={5.8} xs={5.8}>
                              <GroupReportCountItem
                                title={card.title}
                                previousReport={reportData.previousCount || 0}
                                currentReport={reportData.count || 0}
                                isProUser={isProUser}
                                reportType={selectedTimeFilter}
                                showButtons={card.showButtons}
                                onViewReportClicked={() => {
                                  onViewReportClicked(card.code);
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={5.8} sm={5.8} xs={12}>
                  <Grid container gap={1} justifyContent={'center'}>
                    <Grid item sm={5.8} md={5.8} xs={12}>
                      <ResponsiveContainer width='100%' height={300}>
                        <PieChart>
                          <Pie
                            data={activityPercentages}
                            dataKey='value'
                            nameKey='title'
                            cx='50%'
                            cy='50%'
                            outerRadius='60%'
                            innerRadius='30%'
                            label={renderCustomizedLabel}
                            labelLine={false}
                            blendStroke={true}
                          >
                            {activityCards.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item sm={5.8} md={5.8} xs={12}>
                      <Box className={styles.activityContainerHeader}>
                        <Typography
                          className={styles.heading}
                          sx={{
                            width: { xs: '100%', color: AppColors.blackColor },
                          }}
                        >
                          Team Snap
                        </Typography>
                        <Box className={styles.btnContainer}>
                          <Select
                            id='time-filter-select'
                            value={selectedTimeFilter}
                            onChange={handleChangeTimeFilter}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                              backgroundColor: AppColors.primaryColor,
                              borderRadius: '12px',
                              color: 'white',
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': {
                                border: 0,
                              },
                              '& svg': {
                                color: '#ffffff',
                              },
                            }}
                            className={styles.activityFilterDropDown}
                          >
                            {timeFilterList.map((val: any) => {
                              return (
                                <MenuItem value={val}>
                                  <Box
                                    className={
                                      styles.activityFilterDropDownText
                                    }
                                  >
                                    {val}
                                  </Box>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                      </Box>
                      <Stack
                        mt={1}
                        gap={0.5}
                        sx={{
                          padding: '10px',
                          backgroundColor: AppColors.whiteColor,
                          border: `${AppColors.strokeColor} 1px solid`,
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        {activityCards?.length &&
                          activityCards.map((card, inx) => {
                            return (
                              <>
                                <Box
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                >
                                  <Typography variant='body2'>
                                    {card.title}
                                  </Typography>

                                  <Typography variant='body2'>
                                    {(
                                      _.find(activityPercentages, {
                                        title: card.title,
                                      })?.value ?? 0
                                    ).toFixed(1)}
                                    %
                                  </Typography>
                                </Box>

                                {inx < activityCards.length - 1 && (
                                  <Divider sx={{ marginLeft: '40%' }} />
                                )}
                              </>
                            );
                          })}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Box mt={2} display={'flex'} alignItems={'center'}>
                    <Box
                      component={'img'}
                      src='/static/images/ic_message_center.png'
                      height={'35px'}
                    />
                    <Typography
                      component={'span'}
                      variant='h6'
                      color={'primary'}
                      ml={1}
                    >
                      Message Center
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    display={'flex'}
                    flexDirection={'column'}
                    alignContent={'center'}
                    sx={{
                      padding: '10px',
                      backgroundColor: AppColors.whiteColor,
                      border: `${AppColors.strokeColor} 1px solid`,
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography component={'span'} sx={{ fontSize: '1rem' }}>
                        Send a Team Message
                      </Typography>
                      <Box
                        component={'img'}
                        src='/static/images/ic_user_group.png'
                        height={'25px'}
                      />
                    </Box>

                    <Stack>
                      {(messageMedia.length > 0 || isVideoLoading) && (
                        <ImageList sx={{ width: '320px', pt: 1 }} cols={3}>
                          {messageMedia.map((mediaItem, index) =>
                            index < 3 ? (
                              <ImageListItem key={`media-${index}`}>
                                <Box
                                  component={'img'}
                                  src={
                                    mediaItem?.thumbnail != undefined ||
                                    mediaItem?.image != undefined
                                      ? URL.createObjectURL(
                                          mediaItem?.thumbnail ??
                                            mediaItem.image ??
                                            new Blob()
                                        )
                                      : ''
                                  }
                                  alt={''}
                                  loading='lazy'
                                  sx={{
                                    height: 100,
                                    width: 100,
                                    objectFit: 'cover',
                                  }}
                                />
                                <Stack
                                  justifyContent='center'
                                  alignContent={'center'}
                                  alignItems={'center'}
                                  sx={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      p: 0,
                                      borderRadius: '50%',
                                      textAlign: 'center',
                                      backgroundColor: '#0000009e',
                                      color: '#ffffff',
                                      height: '25px',
                                      width: '25px',
                                    }}
                                    onClick={() =>
                                      handleRemoveSelectedMedia(mediaItem)
                                    }
                                  >
                                    <CloseIcon />
                                  </Box>
                                </Stack>
                              </ImageListItem>
                            ) : (
                              <></>
                            )
                          )}

                          {isVideoLoading && (
                            <ImageListItem key={`media-loading`}>
                              <VideoLibraryIcon
                                sx={{
                                  height: 100,
                                  width: 100,
                                  objectFit: 'cover',
                                  color: '#088ac6',
                                }}
                              />
                              <Stack
                                justifyContent='center'
                                alignContent={'center'}
                                alignItems={'center'}
                                sx={{
                                  height: '100%',
                                  width: '100%',
                                  position: 'absolute',
                                }}
                              >
                                <Box
                                  sx={{
                                    p: 0,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    backgroundColor: '#0000009e',
                                    color: '#ffffff',
                                    height: '40px',
                                    width: '40px',
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              </Stack>
                            </ImageListItem>
                          )}
                        </ImageList>
                      )}
                    </Stack>

                    <Box
                      mt={1}
                      display={'flex'}
                      alignContent={'center'}
                      sx={{
                        // padding: '10px',
                        backgroundColor: '#ADD8E6',
                        border: `${AppColors.strokeColor} 1px solid`,
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <InputGroupMessage
                        message={message}
                        setMessage={setMessage}
                        sendMessage={handleSendMessage}
                        selectMedia={onSelectMediaTapped}
                        isMediaSelectionEnable={messageMedia.length < 3}
                      />
                      <input
                        type='file'
                        id='file'
                        ref={fileUploader}
                        onClick={(e: any) => (e.currentTarget.value = '')}
                        onChange={onFileSelect}
                        style={{ display: 'none' }}
                        accept={[
                          ...(selectMediaType == MediaType.video
                            ? acceptVideoFileTypes
                            : acceptImageFileTypes),
                        ].join(',')}
                      />
                    </Box>
                    <Box display={'flex'} justifyContent={'end'} gap={1} pt={1}>
                      {/* <Box
                        component={'img'}
                        src='/static/images/ic_attach.png'
                        height={'30px'}
                      /> */}
                      <Box
                        component={'img'}
                        src='/static/images/ic_picture.png'
                        height={'30px'}
                        onClick={() => onSelectMediaTapped(MediaType.image)}
                      />
                      <Box
                        component={'img'}
                        src='/static/images/ic_video.png'
                        height={'30px'}
                        onClick={() => onSelectMediaTapped(MediaType.video)}
                      />
                    </Box>
                  </Box>
                  <Box
                    mt={1}
                    display={'flex'}
                    flexDirection={'column'}
                    alignContent={'center'}
                    sx={{
                      padding: '10px',
                      backgroundColor: AppColors.whiteColor,
                      border: `${AppColors.strokeColor} 1px solid`,
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography component={'span'} sx={{ fontSize: '1rem' }}>
                        Send a <b>member</b> a message
                      </Typography>
                      <Box
                        component={'img'}
                        src='/static/images/ic_user_blue.png'
                        height={'25px'}
                      />
                    </Box>
                    <Box
                      alignSelf={'center'}
                      width={{ xs: '100%', sm: '100%', md: '70%', lg: '50%' }}
                      mt={1}
                    >
                      <SearchTeamMemberView
                        affiliateGroupId={user?.affiliate_group?.id}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Box
              display={'flex'}
              ref={reportDetailRef}
              sx={{ bottom: 100 }}
              className={styles.mainContentBody}
            >
              {activeBtn === 'mediaVideoViewCount' && (
                <GroupMediaVideoTrackingView scrollToTop={scrollToTop} />
              )}

              {activeBtn === 'groupLandingPageView' && (
                <GroupLandingPageTrackingView scrollToTop={scrollToTop} />
              )}

              {activeBtn === 'viewSocialPosts' && (
                <GroupSocialVideoTrackingView scrollToTop={scrollToTop} />
              )}
            </Box> */}
          </Box>
        </Container>
      </Page>
    </>
  );
};
export default GroupReport;
