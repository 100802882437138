import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './api/interface';
import { getChatUsers } from './api';

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    reset: () => initialState,
    updateChatChannels: (state, action) => {
      state.chatChannels = action.payload;
    },
    setGroupChat: (state, action) => {
      state.groupChat = {...action.payload, users: Object.values(action.payload?.users)};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatUsers.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getChatUsers.fulfilled, (state, action) => {
      let userIds = state.userList.map((item) => item.id);
      state.userList = [
        ...state.userList,
        ...action.payload.data.data.users.filter(
          (item) => !userIds.includes(item.id)
        ),
      ];
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(getChatUsers.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { reset, updateChatChannels, setGroupChat } = chat.actions;
export default chat.reducer;
