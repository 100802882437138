import React, { useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { getCommunityUsers } from '../store/communityUsers/api';
import {
  reset as resetSelect,
  selectMember,
  setSearchUserKeyWord,
} from '../store/communityUsers/communityUsersSlice';
import { useAppDispatch, useAppSelector } from '../store';
import { debounce } from 'lodash';
import { AppColors } from '../utils/utils';
import { UserInfo } from '../store/userInfo/api/interface';
import { useNavigate } from 'react-router-dom';
import { inputTextFieldStyle } from '../utils/utilMethods';
import SearchIcon from '@mui/icons-material/Search';
import MessageIcon from '@mui/icons-material/Message';
import { encryptString } from '../utils/url';

const SearchTeamMemberView = ({
  affiliateGroupId,
}: {
  affiliateGroupId?: number;
}) => {
  const navigate = useNavigate();
  const observer = useRef<IntersectionObserver>();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state?.auth);

  const {
    data,
    isLoading,
    page,
    isLoadMore,
    status,
    selectedMember,
    searchKeyWord,
  } = useAppSelector((state) => state.communityUsers);

  const handleLoadMore = (isReload: boolean) => {
    if (searchKeyWord != '') {
      dispatch(
        getCommunityUsers({
          from_lat: '0.0',
          from_lng: '0.0',
          filter_distance: '100000',
          sort_by: 'rating',
          page: isReload ? 1 : page,
          filter_keyword: searchKeyWord,
          affiliate_group_id: affiliateGroupId,
        })
      );
    }
  };

  const setFilterKeyWord = (keyWords: string) => {
    dispatch(setSearchUserKeyWord(keyWords));
  };

  useEffect(() => {
    if (searchKeyWord) {
      handleLoadMore(true);
    }
  }, [searchKeyWord]);

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && isLoadMore) {
          handleLoadMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isLoadMore]
  );

  const searchCommunityUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilterKeyWord('');
      dispatch(resetSelect());
      return;
    }
    setFilterKeyWord(e.target.value ?? '');
  };

  const debounceWithSearch = debounce(searchCommunityUser, 700);

  let handleOpenVbc = (selectedUser: UserInfo) => {
    try {
      dispatch(selectMember(selectedUser));

      // let userVbcLink = `${config.VBC_URL}/${userName ?? ''}`;
      // window.open(userVbcLink, "_blank") || window.location.replace(userVbcLink);
    } catch (e) {}
  };

  const handleMessageClick = (selectedUser: UserInfo) => {
    if (selectedUser != undefined && user != undefined) {
      var chatName =
        selectedUser.id < user.id
          ? `${selectedUser.id}-${user.id}`
          : `${user.id}-${selectedUser.id}`;
      navigate(`/dashboard/messaging?cid=${encryptString(chatName)}`);
    }
  };

  useEffect(() => {
    if (selectedMember != undefined && selectedMember != null) {
      navigate('/dashboard/vbc');
    }
  }, [selectedMember]);

  return (
    <>
      <TextField
        fullWidth
        key={'search-member'}
        placeholder={'Search a Team Member'}
        InputProps={{
          sx: {
            ...inputTextFieldStyle(),
            borderRadius: '50px',
            border: '3px solid #239fce',
            p: 0,
            m: 0,
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
            maxHeight: '50px',
          },
          type: 'search',
          startAdornment: (
            <React.Fragment>
              <Box
                component={'div'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50px',
                  backgroundColor: '#239fce',
                  height: '47px',
                  width: '68px',
                  ml: '-1px',
                  marginRight: 3,
                }}
              >
                <SearchIcon
                  sx={{ color: 'white', transform: 'rotate(90deg)' }}
                />
              </Box>
            </React.Fragment>
          ),
          // endAdornment: (
          //   isLoading ? (<CircularProgress size={28} sx={{ mr: 1 }} />) : (<></>)
          // ),
        }}
        defaultValue={searchKeyWord}
        onChange={debounceWithSearch}
      />

      {data?.map((userItem, idx) => {
        return (
          <>
            <Card
              ref={idx + 1 === data?.length ? lastElementRef : undefined}
              key={idx}
              sx={{
                width: '100%',
                boxShadow: 'unset !important',
                p: 0,
                mt: 1,
              }}
              className='we-are-live-post-card'
            >
              <ListItem
                onClick={() => handleMessageClick(userItem)}
                alignItems='flex-start'
                secondaryAction={
                  <Box>
                    {/* <Box
                      component={'img'}
                      src={AddUserIcon}
                      alt='search icon'
                      sx={{
                        width: '30px',
                        height: '30px',
                        mr: 1,
                        color: AppColors.primaryColor,
                      }}
                    /> */}
                    <MessageIcon color={'primary'} />
                  </Box>
                }
              >
                <ListItemAvatar>
                  <Box
                    component={'img'}
                    loading='lazy'
                    width='40px'
                    height='40px'
                    borderRadius={'50%'}
                    src={`${
                      userItem.profile_picture ??
                      '/static/images/placeholder_profile.png'
                    }`}
                    alt=''
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontSize: 'medium',
                          fontWeight: 500,
                        }}
                        component='span'
                        color={AppColors.blackColor}
                      >
                        {userItem.name}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline', fontSize: 'small' }}
                        component='span'
                        variant='body2'
                        color={AppColors.grayTwoColor}
                      >
                        {userItem.occupation ?? ''}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Card>
          </>
        );
      })}

      {isLoading && (
        <Box display='flex' justifyContent='center' mt={2}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default SearchTeamMemberView;
