import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { toastHandler } from '../../../utils/toastHandler';
import {
  CorporateGroupLandingPageVideoViewResponse,
  CorporateGroupMediaVideoViewResponse,
  GroupUserAndGoalCountResponse,
  GroupMediaResponse,
  MediaFeedbackFormCountResponse,
  ShareActivityCountReponse,
  SpecificGroupMediaShareActivityCountResponse,
  SpecificGroupMediaViewedActivityCountResponse,
  ViewedActivityCountResponse,
  ReportResponse,
  PostWatchCountResponse,
} from './interface';

export const getShareActivityCount = createAsyncThunk(
  'corporateReporting/shareActivityCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ShareActivityCountReponse>(
        `/api/reports/corporate/share-activity`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getSpecificGroupMediaShareActivityCount = createAsyncThunk(
  'corporateReporting/specificGroupMediaShareActivityCount',
  async (data: { days: number; media_ids: number[] }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post<SpecificGroupMediaShareActivityCountResponse>(
          `/api/reports/corporate/selected-group-media-share-activity`,
          data
        );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getViewActivityCount = createAsyncThunk(
  'corporateReporting/viewActivityCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<ViewedActivityCountResponse>(
        `/api/reports/corporate/view-activity`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getSpecificGroupMediaViewdActivityCount = createAsyncThunk(
  'corporateReporting/specificGroupMediaViewdActivityCount',
  async (data: { days: number; media_ids: number[] }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post<SpecificGroupMediaViewedActivityCountResponse>(
          `/api/reports/corporate/selected-group-media-view-activity`,
          data
        );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaFeedbackFormCount = createAsyncThunk(
  'corporateReporting/mediaFeedbackFormCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<MediaFeedbackFormCountResponse>(
        `/api/reports/corporate/media-feedback`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getGroupMedia = createAsyncThunk(
  'corporateReporting/groupMedia',
  async (data: { groupId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<GroupMediaResponse>(
        `/api/share-affiliate-group-media/media/${data.groupId}`
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCorporateGroupMediaVideoView = createAsyncThunk(
  'corporateReporting/corporateGroupMediaVideoView',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post<CorporateGroupMediaVideoViewResponse>(
          `/api/reports/corporate/media-video-view`,
          data
        );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCorporateGroupLandingPageVideoView = createAsyncThunk(
  'corporateReporting/corporatGroupLandingPageVideoView',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post<CorporateGroupLandingPageVideoViewResponse>(
          `/api/reports/corporate/landing-page-video-view`,
          data
        );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getGroupUserAndGoalCount = createAsyncThunk(
  'corporateReporting/groupUserAndGoalCount',
  async (data: { days: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<GroupUserAndGoalCountResponse>(
        `/api/reports/group-stats/engage-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getAllReportTotal = createAsyncThunk(
  'corporateReporting/getAllReportTotal',
  async (
    data: { userId: number; days?: number; isFromKnoCardLink?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post<ReportResponse>(
        `/api/reports/group-stats/view-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getMediaPostReportCount = createAsyncThunk(
  'corporateReporting/getMediaPostReportCount',
  async (data: { postId: number; days?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<PostWatchCountResponse>(
        `/api/reports/group-stats/media-post-report-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCommunityPostReportCount = createAsyncThunk(
  'corporateReporting/getCommunityPostReportCount',
  async (data: { postId: number; days?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<PostWatchCountResponse>(
        `/api/reports/group-stats/community-post-report-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getLandingPageVideoReportCount = createAsyncThunk(
  'corporateReporting/getLandingPageVideoReportCount',
  async (data: { title: string; days?: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<PostWatchCountResponse>(
        `/api/reports/group-stats/landing-page-video-report-count`,
        data
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

