import { createSlice } from '@reduxjs/toolkit';
import {
  getCorporateGroupLandingPageVideoView,
  getCorporateGroupMediaVideoView,
  getGroupUserAndGoalCount,
  getGroupMedia,
  getMediaFeedbackFormCount,
  getShareActivityCount,
  getSpecificGroupMediaShareActivityCount,
  getSpecificGroupMediaViewdActivityCount,
  getViewActivityCount,
  getAllReportTotal,
  getMediaPostReportCount,
  getCommunityPostReportCount,
  getLandingPageVideoReportCount,
} from './api';
import { initialState } from './api/interface';

const corporateReportingSlice = createSlice({
  name: 'corporateReporting',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getShareActivityCount.pending, (state) => {
      state.isShareActivityCountLoading = true;
    });
    builder.addCase(getShareActivityCount.fulfilled, (state, action) => {
      state.shareActivityCount = action?.payload?.data?.data;
      state.isShareActivityCountLoading = false;
    });
    builder.addCase(getShareActivityCount.rejected, (state, _) => {
      state.isShareActivityCountLoading = false;
    });

    builder.addCase(
      getSpecificGroupMediaShareActivityCount.pending,
      (state) => {
        state.isSpecificGroupMediaShareActivityCountLoading = true;
      }
    );
    builder.addCase(
      getSpecificGroupMediaShareActivityCount.fulfilled,
      (state, action) => {
        state.specificGroupMediaShareActivityCount =
          action?.payload?.data?.data;
        state.isSpecificGroupMediaShareActivityCountLoading = false;
      }
    );
    builder.addCase(
      getSpecificGroupMediaShareActivityCount.rejected,
      (state, _) => {
        state.isSpecificGroupMediaShareActivityCountLoading = false;
      }
    );

    builder.addCase(getViewActivityCount.pending, (state) => {
      state.isViewedActivityCountLoading = true;
    });
    builder.addCase(getViewActivityCount.fulfilled, (state, action) => {
      state.viewedActivityCount = action?.payload?.data?.data;
      state.isViewedActivityCountLoading = false;
    });
    builder.addCase(getViewActivityCount.rejected, (state, _) => {
      state.isViewedActivityCountLoading = false;
    });

    builder.addCase(
      getSpecificGroupMediaViewdActivityCount.pending,
      (state) => {
        state.isSpecificGroupMediaViewedActivityCountLoading = true;
      }
    );
    builder.addCase(
      getSpecificGroupMediaViewdActivityCount.fulfilled,
      (state, action) => {
        state.specificGroupMediaViewedActivityCount =
          action?.payload?.data?.data;
        state.isSpecificGroupMediaViewedActivityCountLoading = false;
      }
    );
    builder.addCase(
      getSpecificGroupMediaViewdActivityCount.rejected,
      (state, _) => {
        state.isSpecificGroupMediaViewedActivityCountLoading = false;
      }
    );

    builder.addCase(getMediaFeedbackFormCount.pending, (state) => {
      state.isMediaFeedbackFormCountLoading = true;
    });
    builder.addCase(getMediaFeedbackFormCount.fulfilled, (state, action) => {
      state.mediaFeedbackFormCount = action?.payload?.data?.data;
      state.isMediaFeedbackFormCountLoading = false;
    });
    builder.addCase(getMediaFeedbackFormCount.rejected, (state, _) => {
      state.isMediaFeedbackFormCountLoading = false;
    });

    builder.addCase(getGroupMedia.pending, (state) => {
      state.isGroupMediaLoading = true;
    });
    builder.addCase(getGroupMedia.fulfilled, (state, action) => {
      state.groupMedia = action?.payload?.data?.data || [];
      state.isGroupMediaLoading = false;
    });
    builder.addCase(getGroupMedia.rejected, (state, _) => {
      state.isGroupMediaLoading = false;
    });

    builder.addCase(getCorporateGroupMediaVideoView.pending, (state) => {
      state.isCorporateGroupMediaVideoViewLoading = true;
    });
    builder.addCase(
      getCorporateGroupMediaVideoView.fulfilled,
      (state, action) => {
        state.corporateGroupMediaVideoView = action?.payload?.data?.data || [];
        state.isCorporateGroupMediaVideoViewLoading = false;
      }
    );
    builder.addCase(getCorporateGroupMediaVideoView.rejected, (state, _) => {
      state.isCorporateGroupMediaVideoViewLoading = false;
    });

    builder.addCase(getCorporateGroupLandingPageVideoView.pending, (state) => {
      state.isCorporateGroupLandingPageVideoViewLoading = true;
    });
    builder.addCase(
      getCorporateGroupLandingPageVideoView.fulfilled,
      (state, action) => {
        state.corporateGroupLandingPageVideoView =
          action?.payload?.data?.data || [];
        state.isCorporateGroupLandingPageVideoViewLoading = false;
      }
    );
    builder.addCase(
      getCorporateGroupLandingPageVideoView.rejected,
      (state, _) => {
        state.isCorporateGroupLandingPageVideoViewLoading = false;
      }
    );

    builder.addCase(getGroupUserAndGoalCount.pending, (state) => {
      state.isCorporateGroupUserAndGoalCountLoading = true;
    });
    builder.addCase(getGroupUserAndGoalCount.fulfilled, (state, action) => {
      state.corporateGroupUserAndGoalCount = action?.payload?.data?.data;
      state.isCorporateGroupUserAndGoalCountLoading = false;
    });
    builder.addCase(getGroupUserAndGoalCount.rejected, (state, _) => {
      state.isCorporateGroupUserAndGoalCountLoading = false;
    });

    builder.addCase(getAllReportTotal.pending, (state) => {
      state.isAllReportSummaryLoading = true;
    });
    builder.addCase(getAllReportTotal.fulfilled, (state, action) => {
      state.allReportSummary = action?.payload?.data?.data;
      state.isAllReportSummaryLoading = false;
    });
    builder.addCase(getAllReportTotal.rejected, (state, _) => {
      state.isAllReportSummaryLoading = false;
    });

    builder.addCase(getMediaPostReportCount.pending, (state) => {
      state.isPostWatchCountLoading = true;
    });
    builder.addCase(getMediaPostReportCount.fulfilled, (state, action) => {
      state.postWatchCount = action?.payload?.data?.data;
      state.isPostWatchCountLoading = false;
    });
    builder.addCase(getMediaPostReportCount.rejected, (state, _) => {
      state.isPostWatchCountLoading = false;
    });

    builder.addCase(getCommunityPostReportCount.pending, (state) => {
      state.isPostWatchCountLoading = true;
    });
    builder.addCase(getCommunityPostReportCount.fulfilled, (state, action) => {
      state.postWatchCount = action?.payload?.data?.data;
      state.isPostWatchCountLoading = false;
    });
    builder.addCase(getCommunityPostReportCount.rejected, (state, _) => {
      state.isPostWatchCountLoading = false;
    });

    builder.addCase(getLandingPageVideoReportCount.pending, (state) => {
      state.isPostWatchCountLoading = true;
    });
    builder.addCase(
      getLandingPageVideoReportCount.fulfilled,
      (state, action) => {
        state.postWatchCount = action?.payload?.data?.data;
        state.isPostWatchCountLoading = false;
      }
    );
    builder.addCase(getLandingPageVideoReportCount.rejected, (state, _) => {
      state.isPostWatchCountLoading = false;
    });
  },
});

export const { reset } = corporateReportingSlice.actions;
export default corporateReportingSlice.reducer;
