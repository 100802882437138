import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AppColors } from '../../utils/utils';
import { ReportCountItem } from './ReportContItem';

interface IProps {
  title: string;
  previousReport: number;
  currentReport: number;
  isProUser: boolean;
  showButtons: boolean;
  reportType: string;
  onViewReportClicked: () => void;
}

export const GroupReportCountItem: React.FC<IProps> = ({
  title,
  previousReport,
  currentReport,
  isProUser,
  showButtons,
  reportType,
  onViewReportClicked,
}) => {
  return (
    <Box
      sx={{
        padding: '10px',
        backgroundColor: AppColors.whiteColor,
        border: `${AppColors.strokeColor} 1px solid`,
        borderRadius: '10px',
        width: '100%',
      }}
    >
      <ReportCountItem
        title={title}
        previousReport={previousReport}
        currentReport={currentReport}
        isProUser={isProUser}
        reportType={reportType}
        titleFontWeight={'600'}
      />
      {showButtons && (
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          // justifyContent={'space-between'}
          paddingTop={'10px'}
          gap={2}
        >
          <Button
            variant={'contained'}
            sx={{
              textTransform: 'unset',
              borderRadius: '20px',
              width: '80%',
            }}
            onClick={onViewReportClicked}
          >
            <Typography variant='caption'>View Report</Typography>
          </Button>

          {/* <Button
            variant={'outlined'}
            sx={{
              textTransform: 'unset',
              borderRadius: '20px',
              width: '100%',
            }}
            onClick={() => {
              // onOpen();
            }}
          >
            <Typography variant='caption'>Download Report</Typography>
          </Button> */}
        </Stack>
      )}
    </Box>
  );
};
