import * as Yup from 'yup';

const TeamSetupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Team name is required. Please add your team name.'),
});
export default TeamSetupSchema;
