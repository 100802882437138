import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Card,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Button,
} from '@mui/material';
import Page from '../../components/Page';
import { AppDispatch, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import React, { useCallback, FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useToggle from '../../hooks/useToggle';
import useWindowSize from '../../utils/useWindowSize';
import { useNavigate } from 'react-router-dom';
import ConfirmationModel from '../../components/modal/ConfirmationModel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import YouTube from 'react-youtube';
import moment from 'moment';
import ReactReadMoreReadLess from '../../components/ReactReadMoreReadLess';
import { encryptString } from '../../utils/url';
import { isNotEmpty } from '../../utils/valid';
import { AppColors } from '../../utils/utils';
import { showSuccessMessage } from '../../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import AddSocialPostIcon from '../../assets/social/add_social.svg';
import { GroupSocialPostData } from '../../store/AffiliateGroup/api/interface';
import {
  deleteGroupSocialPost,
  getAffiliateGroupSocialPosts,
  updateSocialPostActiveStatus,
} from '../../store/AffiliateGroup/api';
import {
  socialPostDeleted,
  socialPostUpdated,
} from '../../store/AffiliateGroup/AffiliateGroupSlice';

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const GroupSocialPost = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const userInfo = useAppSelector((state) => state?.auth?.user);

  const socialPosts =
    useAppSelector(
      (state) => state?.affiliateGroup.affiliateGroupSocialPosts
    ) ?? [];

  const isLoading = useAppSelector(
    (state) => state?.affiliateGroup?.isSocialPostLoading
  );
  const hasMore = useAppSelector(
    (state) => state?.affiliateGroup?.isSocialPostLoadMore
  );
  const currentPage = useAppSelector(
    (state) => state?.affiliateGroup?.socialPostPage
  );

  const [openEditForm, setOpenEditForm] = useState(false);
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [changeStatusPost, setChangeStatusPost] = useState<
    GroupSocialPostData | undefined
  >();

  const {
    toggle: toggleChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useToggle();

  const [deleteSocialPost, setDeleteSocialPost] = useState<
    GroupSocialPostData | undefined
  >();

  const {
    toggle: toggleDeleteSocialPost,
    onOpen: onOpenDeleteSocialPost,
    onClose: onCloseDeleteSocialPost,
  } = useToggle();

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleLoadMore = () => {
    if (!isLoading) {
      dispatch(
        getAffiliateGroupSocialPosts({
          groupId: userInfo?.affiliate_group?.id ?? -1,
          page: currentPage,
        })
      );
    }
  };

  useEffect(() => {
    if (userId) {
      if (socialPosts.length == 0) {
        handleLoadMore();
      }
    }
  }, [userId]);

  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches
      ? windowSize.width > 350
        ? windowSize.width * 0.9
        : windowSize.width * 0.8
      : windowSize.width > 890
      ? 745
      : windowSize.width * 0.8;

    setCardWidth(newWidth);
  }, [windowSize]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState<any>(0);

  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenu = (companyFeedItem: GroupSocialPostData) => {
    navigate(
      `/dashboard/edit-group-social/${encryptString(
        `${companyFeedItem.id ?? ''}`
      )}`
    );
    setAnchorEl(null);
  };

  const handleChangeStatusMenu = (post: GroupSocialPostData) => {
    setChangeStatusPost(post);
    onOpenChangeStatus();
    setAnchorEl(null);
  };

  const onChangeStatusConfirm = async () => {
    onCloseChangeStatus();
    if (changeStatusPost != undefined) {
      try {
        var result = await dispatch(
          updateSocialPostActiveStatus({
            groupId: changeStatusPost.group_id ?? -1,
            mediaPostId: changeStatusPost.id,
            status: changeStatusPost.status == 1 ? 0 : 1,
          })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(socialPostUpdated(result.data.request));
          showSuccessMessage('Status has been changed successfully.');
          setChangeStatusPost(undefined);
        }
      } catch (error: any) {}
    }
  };

  const handleDeleteMenu = (companyFeedItem: GroupSocialPostData) => {
    setDeleteSocialPost(companyFeedItem);
    onOpenDeleteSocialPost();
    setAnchorEl(null);
  };

  const onDeleteSocialPostConfirm = async () => {
    onCloseDeleteSocialPost();
    if (deleteSocialPost != undefined) {
      try {
        var result = await dispatch(
          deleteGroupSocialPost({ postId: deleteSocialPost.id })
        ).unwrap();
        if (result.data.status.code == 'success') {
          dispatch(socialPostDeleted(deleteSocialPost));
          showSuccessMessage('Group social post deleted successfully.');
          setDeleteSocialPost(undefined);
        }
      } catch (error: any) {}
    }
  };

  const handleAddSocialPost = () => {
    navigate('/dashboard/add-group-social');
  };

  const handleOnCommandCenterClicked = () => {
    navigate('/dashboard/leader-corner');
  };

  return (
    <>
      <Page title='Group Social | KnoCard' showAppBar={true}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={3}
          sx={{ mb: 1 }}
        >
          <Box
            component={'img'}
            // className='cover-img'
            src={
              isNotEmpty(userInfo?.affiliate_group?.background_link)
                ? userInfo?.affiliate_group?.background_link
                : '/static/images/group_bg/knocard.jpg'
            }
            sx={{
              borderRadius: '12px',
              objectFit: 'cover',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              width: '100%',
              mt: 2,
            }}
          />
          <Button
            variant='outlined'
            sx={{
              alignSelf: 'start',
              fontWeight: '500',
              fontSize: '1.3rem',
              textTransform: 'capitalize',
              marginLeft: '10px',
              color: AppColors.whiteColor,
              background: AppColors.primaryColor,
              borderRadius: '12px',
              minWidth: { xs: '50%', md: '20%' },
              maxHeight: '70px',
              ':hover': {
                color: AppColors.primaryColor,
              },
            }}
            onClick={handleOnCommandCenterClicked}
          >
            Go To Command Center
          </Button>

          <Grid
            container
            spacing={3}
            justifyContent={'center'}
            sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
          >
            <Grid item xs={12} sm={12} md={8}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={1}
              >
                {userInfo?.username ==
                  userInfo?.affiliate_group?.owner_name && (
                  <Box
                    key={'add-social-post'}
                    sx={{
                      width: '100%',
                      pl: 2,
                      pr: 2,
                      mb: 3,
                    }}
                  >
                    <Box display='flex' alignItems='center' height='60px'>
                      <Box>
                        <Avatar
                          alt='pic'
                          src={
                            userInfo?.profile_picture ??
                            '/static/images/placeholder_profile.png'
                          }
                          sx={{
                            height: '50px',
                            width: '50px',
                          }}
                        />
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'
                        flex={1}
                        sx={{
                          ml: 1.3,
                          mr: 1.3,
                          backgroundColor: AppColors.grayZeroColor,
                          border: `1px solid ${AppColors.strokeColor}`,
                          p: 2,
                          borderRadius: '10px',
                        }}
                        onClick={handleAddSocialPost}
                      >
                        <Typography sx={{ color: AppColors.grayThreeColor }}>
                          What's New?
                        </Typography>
                      </Box>
                      <Box
                        component={'img'}
                        src={AddSocialPostIcon}
                        onClick={handleAddSocialPost}
                      />
                    </Box>
                  </Box>
                )}

                {socialPosts?.map((post, idx) => {
                  return (
                    <Card
                      ref={
                        idx + 1 === socialPosts?.length
                          ? lastElementRef
                          : undefined
                      }
                      key={idx}
                      sx={{
                        width: '100%',
                        boxShadow: 'unset !important',
                        p: 0,
                      }}
                      className='we-are-live-post-card'
                    >
                      <Stack direction='column' gap={1}>
                        <Box
                          display='flex'
                          alignItems='center'
                          height='60px'
                          sx={{ pl: 1.4, pt: 1 }}
                        >
                          <Box width='50px'>
                            <Avatar
                              alt='pic'
                              src={
                                userInfo?.profile_picture ??
                                '/static/images/placeholder_profile.png'
                              }
                            />
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                            justifyContent='center'
                            flex={1}
                          >
                            <Stack flexDirection={'row'}>
                              <Typography
                                variant='h6'
                                component='div'
                                color='black'
                                className='post-main-title'
                                sx={{ color: AppColors.blackColor }}
                              >
                                {userInfo?.name ?? ''}
                              </Typography>
                            </Stack>
                            <Typography
                              variant='h6'
                              component='div'
                              fontSize='10px'
                              className='post-main-sub-title'
                              sx={{ color: AppColors.grayTwoColor }}
                            >
                              {userInfo?.occupation ?? ''}

                              <Box
                                component={'span'}
                                sx={{
                                  marginLeft: '10px',
                                  color: AppColors.grayTwoColor,
                                }}
                              >
                                {moment(post?.created_at).format(
                                  'MMM DD, YYYY'
                                )}
                              </Box>
                            </Typography>
                          </Box>

                          {userInfo?.username ==
                            userInfo?.affiliate_group?.owner_name && (
                            <Box>
                              <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-controls={
                                  menuOpen && activeMenuIndex == idx
                                    ? 'long-menu'
                                    : undefined
                                }
                                aria-expanded={
                                  menuOpen && activeMenuIndex == idx
                                    ? 'true'
                                    : undefined
                                }
                                aria-haspopup='true'
                                onClick={(event) => handleMenuClick(event, idx)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id='long-menu'
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={menuOpen && activeMenuIndex == idx}
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                  },
                                }}
                              >
                                <>
                                  <MenuItem
                                    key={`edit-group-social-${idx}`}
                                    onClick={() => handleEditMenu(post)}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    key={`change-status-${idx}`}
                                    onClick={() => handleChangeStatusMenu(post)}
                                  >
                                    {post.status == 1
                                      ? 'Deactivate'
                                      : 'Activate'}
                                  </MenuItem>
                                  <MenuItem
                                    key={`delete-${idx}`}
                                    onClick={() => handleDeleteMenu(post)}
                                  >
                                    Delete
                                  </MenuItem>
                                </>
                              </Menu>
                            </Box>
                          )}
                        </Box>

                        <RenderCompanyMediaFeed companyFeed={post} />
                        <CardContent
                          sx={{
                            pl: 2.5,
                            pr: 2,
                            pt: 0,
                            '&:last-child': { pb: '16px' },
                          }}
                        >
                          <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <Grid item>
                              <Typography
                                variant='body1'
                                gutterBottom
                                sx={{
                                  color: AppColors.blackColor,
                                  fontSize: 'medium',
                                  fontWeight: '500',
                                }}
                              >
                                {post.title}
                              </Typography>
                              <Typography
                                variant='body2'
                                className='link'
                                sx={{
                                  whiteSpace: 'pre-line',
                                  color: AppColors.grayTwoColor,
                                }}
                              >
                                <ReactReadMoreReadLess
                                  charLimit={200}
                                  readMoreText={'Read more'}
                                  readLessText={'Read less'}
                                  readMoreClassName='read-more-less-more'
                                  readLessClassName='read-more-less-less'
                                >
                                  {post.description}
                                </ReactReadMoreReadLess>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Stack>
                    </Card>
                  );
                })}
              </Box>
              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Page>

      <ConfirmationModel
        cancelButtonText='No'
        confirmButtonText='Yes'
        title='Change Status'
        message='Are you sure! Do you want to change status of this post?'
        onConfirm={onChangeStatusConfirm}
        onClose={onCloseChangeStatus}
        toggle={toggleChangeStatus}
      />

      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete social post'
        message='Are you sure! Do you want to delete this group social post?'
        onConfirm={onDeleteSocialPostConfirm}
        onClose={onCloseDeleteSocialPost}
        toggle={toggleDeleteSocialPost}
      />
    </>
  );
};

const RenderCompanyMediaFeed: FC<{ companyFeed: GroupSocialPostData }> = ({
  companyFeed,
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [totalSlide, setTotalSlide] = useState<number>(0);

  if ((companyFeed?.contents?.length || 0) > 0) {
    return (
      <>
        <Splide
          aria-label='Images'
          options={{
            perPage: 1,
            //fixedHeight: 390,
          }}
          onMove={(event) => {
            setActiveSlide(event.index);
          }}
          onReady={(event) => {
            setTotalSlide(event.length);
          }}
        >
          {companyFeed?.contents?.map((content) => {
            return content?.type === 'image' ? (
              <SplideSlide key={content.id}>
                <img
                  src={content?.link}
                  alt={content?.filename ?? ''}
                  height='100%'
                  width='100%'
                  style={{ maxHeight: '50vh' }}
                  className='object-fit-contain'
                />
              </SplideSlide>
            ) : (
              <SplideSlide key={content.id}>
                {content?.platform === 'youtube' ? (
                  <YouTube
                    videoId={content?.link}
                    opts={opts}
                    className='object-fit-contain'
                    style={{
                      height: '100%',
                      width: '100%',
                      maxHeight: '50vh',
                    }}
                    // onPlay={() => handleOnplayVideo(content.id)}
                  />
                ) : content?.platform === 'vimeo' ? (
                  <Vimeo
                    video={content?.link}
                    className='object-fit-contain vimeo-video-contain'
                    height={'100%'}
                    width={'100%'}
                    style={{
                      height: '100%',
                      width: '100%',
                      maxHeight: '50vh',
                    }}
                    // onPlay={() => handleOnplayVideo(content.id)}
                  />
                ) : (
                  <video
                    controls
                    width='100%'
                    height='100%'
                    className='object-fit-contain'
                    poster={content?.thumbnail ?? ''}
                    style={{ maxHeight: '50vh' }}
                    //onPlay={() => handleOnplayVideo(content.id)}
                  >
                    <source src={content?.link} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                )}
              </SplideSlide>
            );
          })}
        </Splide>

        {companyFeed?.contents && companyFeed?.contents?.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant='contained'
              sx={{
                // backgroundColor: '#088ac6',
                backgroundColor: AppColors.lightYellowColor,
                color: AppColors.blackColor,
                textTransform: 'none',
                fontSize: '1rem',
                zIndex: 1000,
                lineHeight: 'normal',
                mt: 0.3,
                mr: 1,
              }}
            >
              {`${activeSlide + 1}`} of {totalSlide ?? 0}
            </Button>
          </Box>
        )}
      </>
    );
  }

  return <></>;
};

export default GroupSocialPost;
