import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../../styles/Reports.module.css';
import Page from '../../components/Page';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '../../store';
import { isNotEmpty } from '../../utils/valid';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppColors } from '../../utils/utils';
import {
  changeAffiliateGroupBanner,
  findAffiliateGroup,
} from '../../store/AffiliateGroup/api';
import { AffiliateGroup } from '../../store/AffiliateGroup/api/interface';
import { LoadingButton } from '@mui/lab';
import { showErrorMessage, showSuccessMessage } from '../../utils/utilMethods';
import { groupUpdated } from '../../store/AffiliateGroup/AffiliateGroupSlice';
import LeadersCornerCard from '../../components/LeadersCornerCard';
import {
  checkIsProTeamsUser,
  checkIsTeamsOrProTeamsUser,
} from '../../utils/checkPro';
import useToggle from '../../hooks/useToggle';
import BlockOverlay from '../../components/BlockOverlay';

const LeaderCorner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);
  const isTeamsUser = checkIsTeamsOrProTeamsUser(user);
  const isProTeamsUser = checkIsProTeamsUser(user);

  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );

  const [groupData, setGroupData] = useState<AffiliateGroup | undefined>(
    undefined
  );

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { data: affiliateGroupList, isLoading: isAffiliateGroupLoading } =
    useAppSelector((state) => state?.affiliateGroup);

  const {
    toggle: toggleProView,
    onOpen: onOpenProView,
    onClose: onCloseProView,
  } = useToggle();

  useEffect(() => {
    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }
  }, []);

  useEffect(() => {
    if (affiliateGroupList.length > 0) {
      affiliateGroupList.forEach((item) => {
        if (item.id === user?.affiliate_group_id) {
          // `${item.owner_name}` === user?.username &&
          setGroupData(item);
        }
      });
    }
  }, [affiliateGroupList, user?.username]);

  const handleOnGroupTrainingMediaClicked = () => {
    if (!groupData) {
      showErrorMessage('Please setup team first.');
      return;
    }

    navigate('/dashboard/group-training-media');
  };

  const handleOnGroupMediaClicked = () => {
    if (!groupData) {
      showErrorMessage('Please setup team first.');
      return;
    }

    navigate('/dashboard/group-media');
  };

  const handleOnTeamStatsClicked = () => {
    if (!groupData) {
      showErrorMessage('Please setup team first.');
      return;
    }

    if (isProTeamsUser) {
      navigate('/dashboard/group-report');
    } else {
      onOpenProView();
    }
  };

  const handleOnGroupSocialPostClicked = () => {
    if (!groupData) {
      showErrorMessage('Please setup team first.');
      return;
    }

    navigate('/dashboard/group-social-post');
  };

  const handleOnTeamSetupClicked = () => {
    navigate('/dashboard/team-setup');
  };

  return (
    <>
      <Page title='Leaders Corner | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box className={styles.leaderCornerBG} sx={{ textAlign: 'center' }}>
            <Box className={styles.leaderCornerBGOverlay} />
            <Grid
              container
              display={'flex'}
              p={5}
              direction={'column'}
              sx={{ position: 'relative', zIndex: 1 }}
            >
              <Grid
                item
                display={'flex'}
                justifyContent={'center'}
                alignContent={'center'}
                sm={12}
                xs={12}
              >
                <Typography
                  display={'flex'}
                  component={'p'}
                  sx={{ fontSize: '3vw', fontWeight: 600, color: 'white' }}
                  alignItems={'center'}
                  alignContent={'center'}
                >
                  The
                  <Box
                    component={'img'}
                    src='/static/images/img_ultimate_team.png'
                    width={'35vw'}
                  />
                  Command Center
                </Typography>
              </Grid>
              <Grid item marginTop={5}>
                <Grid container spacing={3} sx={{ padding: 2 }}>
                  {/* Training */}
                  <LeadersCornerCard
                    index={1}
                    image={'/static/images/img_training.png'}
                    title={'/static/images/txt_training.png'}
                    features={[
                      'Upload MP3s, MP4s, images, PDFs',
                      'Centralized team training resources',
                      'Keep messaging consistent and updated',
                      'Accessible to all team',
                    ]}
                    onClick={handleOnGroupTrainingMediaClicked}
                  />

                  {/* Media upload */}
                  <LeadersCornerCard
                    index={2}
                    image={'/static/images/img_media.png'}
                    title={'/static/images/txt_media_upload.png'}
                    features={[
                      'Upload and distribute media files',
                      'Auto-async content to all team KnoCards',
                      'Ensure brand consistency across team',
                      'Easily manage and upload materials',
                    ]}
                    onClick={handleOnGroupMediaClicked}
                  />

                  {/* Reporting */}
                  <LeadersCornerCard
                    index={3}
                    image={'/static/images/img_reporting.png'}
                    title={'/static/images/txt_reporting.png'}
                    features={[
                      'Track engagement and video performance',
                      'Monitor shares, clicks and views',
                      'View goal progress and team activity',
                      'Gain insights for team growth',
                    ]}
                    onClick={handleOnTeamStatsClicked}
                  />

                  {/* Social Posting */}
                  <LeadersCornerCard
                    index={4}
                    image={'/static/images/img_social.png'}
                    title={'/static/images/txt_social_posting.png'}
                    features={[
                      'Effortless content duplication across team',
                      'Broadcast to multiple social media',
                      'Share company - provided content instantly',
                      'Maintain brand consistency across all posts',
                    ]}
                    onClick={handleOnGroupSocialPostClicked}
                  />
                </Grid>
              </Grid>
              {(`${groupData?.owner_name}` === user?.username ||
                isTeamsUser) && (
                <Grid
                  item
                  sx={{ padding: 2, textAlign: { xs: 'center', md: 'start' } }}
                >
                  <Button
                    variant='outlined'
                    sx={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      textTransform: 'capitalize',
                      color: AppColors.grayThreeColor,
                      background: AppColors.whiteColor,
                      borderRadius: '12px',
                      ':hover': {
                        color: AppColors.whiteColor,
                        background: AppColors.primaryColor,
                      },
                    }}
                    startIcon={
                      <Box
                        component={'img'}
                        src={'/static/images/ic_edit.png'}
                        sx={{ mr: 1, height: '25px' }}
                      />
                    }
                    onClick={handleOnTeamSetupClicked}
                  >
                    Edit Team Settings
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
        <BlockOverlay isBlock={toggleProView} onCloseProView={onCloseProView} />
      </Page>
    </>
  );
};
export default LeaderCorner;
