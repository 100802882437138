export interface Post {
  status: string;
  message: string;
  data: Data;
}

export interface Data {
  current_page: number;
  data: PostObject[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: null;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
}
export interface ShareFormInfoResponse {
  status: string;
  data: {
    id: number;
    user_id: number;
    post_id: number;
    created_at: string;
    updated_at: string;
    action_type: string;
    target_id: number;
    postby_id: number;
    targetable_type: null | string;
    form_number: number;
    feedback_form_id?: number;
    form_answer: null;
    form_code: string;
    feedback_form?: {
      id?: number;
      user_id?: number;
      answer_one?: string;
      answer_two?: string;
      answer_three?: string;
      answer_four?: string;
      question?: string;
      title?: string;
      created_at?: Date;
      updated_at?: Date;
    }
  };
}
export interface PostObject {
  id: number;
  user_id: number;
  description: string;
  thumb_name?: string;
  thumb_link?: string;
  thumb_image?: string;
  thumb_text?: string;
  thumb_text_color?: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  views_count?: number;
  shares_count?: number;
  contents: Content[];
  is_pined: number;
  topic_id?: number;
  group_media_post_id?: number;
}
export interface Content {
  id: number;
  post_id: number;
  user_id: number;
  type: Type;
  link: string;
  thumbnail: null | string;
  status: number;
  created_at: Date;
  updated_at: Date;
  filename: string;
  thumb_filename: null | string;
  title: string;
  description: string;
  platform: string;
}

export interface PostPagination {
  user_id: number;
  page: number;
  topic_id?: number | undefined;
  group_id?: number | undefined;
}

export interface PinPostRequest {
  postId: number;
  isPin: boolean;
}

export enum Type {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Pdf = 'pdf',
}

interface InitialState {
  data: Data;
  status: string;
  hasMore: boolean;
  skip: number;
  currentPage: number;
  isError: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isSavSuccess: boolean;
  isSuccess: boolean;
  message: string;
  pinedData: Data;
  pinedDataStatus: string;
  pinedDataHasMore: boolean;
  pinedDataSkip: number;
  pinedDataCurrentPage: number;
  pinedDataIsError: boolean;
  pinedDataIsLoading: boolean;
  pinedDataIsSaving: boolean;
  pinedDataIsSavSuccess: boolean;
  pinedDataIsSuccess: boolean;
  pinedDataMessage: string;
}

export interface MediaPostContent {
  file?: File;
  type: string;
  thumbnail?: File;
  title: string;
  description?: string;
  link?: string;
  thumbnail_link?: string | null;
  platform: string;
  content?: Content;
}

export const initialState: InitialState = {
  data: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: null,
    path: '',
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  status: '',
  hasMore: false,
  skip: 0,
  currentPage: 1,
  isError: false,
  isLoading: false,
  isSaving: false,
  isSavSuccess: false,
  isSuccess: false,
  message: '',
  pinedData: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: null,
    path: '',
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  pinedDataStatus: '',
  pinedDataHasMore: false,
  pinedDataSkip: 0,
  pinedDataCurrentPage: 1,
  pinedDataIsError: false,
  pinedDataIsLoading: false,
  pinedDataIsSaving: false,
  pinedDataIsSavSuccess: false,
  pinedDataIsSuccess: false,
  pinedDataMessage: '',
};
