import { Box, Button, Grid, Typography } from '@mui/material';
import styles from '../styles/Reports.module.css';
import { AppColors } from '../utils/utils';

interface IProps {
  index: number;
  image: string;
  title: string;
  features: string[];
  onClick: () => void;
}

const LeadersCornerCard: React.FC<IProps> = ({
  index,
  image,
  title,
  features,
  onClick,
}) => {
  return (
    <Grid item xs={12} sm={6} md={3} marginTop={{ xs: 5 }}>
      <Grid
        container
        m={0}
        display={'flex'}
        justifyContent={'center'}
        className={styles.leaderCornerCard}
        height={'100%'}
      >
        <Grid item className={styles.leaderCornerCardHeader}>
          <Box component={'img'} src={title} />
        </Grid>
        <Grid item>
          <Box
            component={'img'}
            src={image}
            className={styles.leaderCornerImg}
          />
        </Grid>
        <Grid item textAlign={'start'}>
          {features.map((item) => (
            <Box component={'li'} pt={1}>
              <Typography component={'span'}>{item}</Typography>
            </Box>
          ))}
        </Grid>
        {/* <Box position={'absolute'} bottom={'5%'} left={5} right={5}> */}
        <Box marginTop={'8%'} alignSelf={'end'} width={'100%'}>
          <Button
            variant='text'
            sx={{
              fontWeight: '500',
              fontSize: '1.3rem',
              textTransform: 'capitalize',
              marginTop: '20px',
              color: AppColors.whiteColor,
              background: AppColors.greenColor,
              borderRadius: '50px',
              minWidth: { xs: '90%', md: '90%' },
              '&:hover': {
                color: AppColors.primaryColor,
              },
            }}
            onClick={onClick}
          >
            Click Here
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LeadersCornerCard;
