import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import contactFormSchema from '../validation/contactFormSchema';
import { postContactForm } from '../store/contactForm/api';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect, useState } from 'react';
import { IContactFormState } from '../store/contactForm/api/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { setSc, setUserInfo, reset } from '../store/userInfo/userInfoSlice';
import { ContactFormSourceType, IHomeParams } from '../enum';
import { AppColors } from '../utils/utils';
import PersonIcon from '../assets/profile/person.svg';
import CallIcon from '../assets/profile/call.svg';
import MailIcon from '../assets/profile/mail.svg';
import { getUserInfo } from '../store/userInfo/api';

interface IProps {}

const ContactFormEmbedded: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const params = useParams<IHomeParams>();
  const userInfo = useAppSelector((state) => state.userInfo.data);

  const navigate = useNavigate();

  const [contactSaved, setContactSaved] = useState<boolean>(false);

  const textFieldStyle = {
    '& label': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root input': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: ` ${AppColors.strokeColor} !important`,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: `${AppColors.primaryColor} !important`,
    },
    '& .MuiInputLabel-shrink': {
      backgroundColor: AppColors.grayZeroColor,
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      background: AppColors.grayZeroColor,
      '& fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&:hover fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: AppColors.strokeColor,
      },
    },
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const username = params?.username;

      if (!username) return;

      const res = await dispatch(getUserInfo({ username })).unwrap();
      if (res.data.data.user.length > 0) {
        dispatch(setUserInfo(res.data));
      } else {
        dispatch(reset());
      }
    };
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik<IContactFormState>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      where_i_met_you: '',
      occupation: '',
      notes: '',
      is_share: 1,
      sender_id: 0,
      phones: [],
    },
    validationSchema: contactFormSchema,
    onSubmit: async (values) => {
      try {
        values.phones = [values.phone_number];
        const response = await dispatch(
          postContactForm({ ...values, source: ContactFormSourceType.QR_FORM })
        ).unwrap();
        if (response && response.data && response.data.data) {
          const sc = response.data.data.contact.contact_code;
          if (sc) {
            resetForm();
            setContactSaved(true);
            dispatch(setSc(sc));
          }
        }

        setterForSenderId();
      } catch (error: any) {}
    },
  });

  function setterForSenderId() {
    setFieldValue('sender_id', userInfo?.id ?? 0);
  }

  useEffect(() => {
    if (userInfo?.id) {
      setterForSenderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return (
    <Box border='1px solid gray' borderRadius={2}>
      {contactSaved ? (
        <>
          <Stack
            display='flex'
            justifyContent={'center'}
            alignItems={'center'}
            height='100%'
            p={2}
          >
            <Typography
              variant='h6'
              sx={{
                color: AppColors.primaryColor,
                textAlign: { md: 'start', xs: 'center' },
              }}
            >
              Contact info saved successfully
            </Typography>

            <Button
              sx={{
                maxWidth: '100px',
                borderColor: AppColors.greenColor,
                backgroundColor: AppColors.greenColor,
                color: '#ffffff',
                textTransform: 'unset',
                fontWeight: '600',
                '&:hover': {
                  borderColor: AppColors.greenColor,
                  background: AppColors.greenColor,
                },
                '& span span': {
                  color: '#ffffff',
                },
              }}
              variant='outlined'
              onClick={() => setContactSaved(false)}
            >
              Okay
            </Button>
          </Stack>
        </>
      ) : (
        <>
          <Typography
            variant='h6'
            sx={{
              color: AppColors.primaryColor,
              textAlign: { md: 'start', xs: 'center' },
            }}
          >
            Please Share Your Contact Info
          </Typography>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <Stack
              direction='column'
              spacing={2}
              sx={{ marginTop: 1, padding: '10px 24px 5px 24px' }}
            >
              <TextField
                id='first_name'
                name='first_name'
                label='First Name*'
                fullWidth
                onChange={handleChange}
                value={values.first_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box component={'img'} src={PersonIcon} />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.first_name && touched.first_name)}
                helperText={
                  Boolean(errors.first_name && touched.first_name) &&
                  errors.first_name
                }
                sx={{ ...textFieldStyle }}
              />

              <TextField
                id='last_name'
                name='last_name'
                label='Last Name'
                fullWidth
                onChange={handleChange}
                value={values.last_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box component={'img'} src={PersonIcon} />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.last_name && touched.last_name)}
                helperText={
                  Boolean(errors.last_name && touched.last_name) &&
                  errors.last_name
                }
                sx={{ ...textFieldStyle }}
              />

              <TextField
                type='tel'
                name='phone_number'
                id='phone_number'
                label='Mobile Number*'
                onChange={handleChange}
                value={values.phone_number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box component={'img'} src={CallIcon} />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.phone_number && touched.phone_number)}
                helperText={
                  Boolean(errors.phone_number && touched.phone_number) &&
                  errors.phone_number
                }
                sx={{ ...textFieldStyle }}
              />

              <TextField
                type='email'
                name='email'
                id='email'
                label='Email Address'
                fullWidth
                value={values.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box component={'img'} src={MailIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                error={Boolean(errors.email && touched.email)}
                helperText={
                  Boolean(errors.email && touched.email) && errors.email
                }
                sx={{ ...textFieldStyle }}
              />

              <Stack direction='column' spacing={2} justifyContent={'flex-end'}>
                <LoadingButton
                  sx={{
                    minWidth: '100px',
                    borderColor: AppColors.greenColor,
                    backgroundColor: AppColors.greenColor,
                    color: '#ffffff',
                    textTransform: 'unset',
                    fontWeight: '600',
                    '&:hover': {
                      borderColor: AppColors.greenColor,
                      background: AppColors.greenColor,
                    },
                    '& span span': {
                      color: '#ffffff',
                    },
                  }}
                  type='submit'
                  loading={isSubmitting}
                  variant='outlined'
                >
                  Submit
                </LoadingButton>
                <Box
                  sx={{
                    paddingX: 3,
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    src={'/static/images/knocard_logo.png'}
                    sx={{ height: '30px' }}
                  ></Box>
                </Box>
              </Stack>
            </Stack>
          </form>
        </>
      )}
    </Box>
  );
};

export default ContactFormEmbedded;
